import styled from "styled-components";

export const NavBar = styled.nav`
  background: ${(props) => props.theme.colors.primary100};
  // background: ${(props) => props.theme.colors.white100};
  box-shadow: ${(props) => props.theme.shadows.nav};
`;

export const NavList = styled.ul`
  display: flex;
  width: 100vw;
  height: 100px;
  align-items: center;
  justify-content: center;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    height: 70px;
  }
`;
