import { NavItem } from "@/components/atoms/NavItem";
import * as S from "./NavBar.style";

interface NavBarProps {
  menu?: any;
}

export const NavBar = ({ menu }: NavBarProps) => {
  return (
    <S.NavBar>
      <S.NavList>
        {menu.map((item: any, idx: number) => {
          return <NavItem data={item} key={idx} />;
        })}
      </S.NavList>
    </S.NavBar>
  );
};
