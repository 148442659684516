import * as S from "./ContentPostItem.style";
import { ReactComponent as IconHistory } from "@/styles/assets/svg/icon_history.svg";
import { ReactComponent as IconFavorite } from "@/styles/assets/svg/icon_favorite.svg";
import { ReactComponent as IconComment } from "@/styles/assets/svg/icon_comment.svg";

export const ContentPostItem = () => {
  return (
    <S.ContentPostItem>
      <S.PostTitBox>
        <S.PostCategory>카테고리</S.PostCategory>
        <S.PostTit>게시글 제목</S.PostTit>
      </S.PostTitBox>

      <S.PostInfoBox>
        <S.PostInfo>
          <IconHistory width={15} height={15} />
          12시간 전
        </S.PostInfo>
        <S.PostInfo>
          <IconFavorite width={15} height={15} />
          3,500
        </S.PostInfo>
        <S.PostInfo>
          <IconComment width={15} height={15} />
          500
        </S.PostInfo>
      </S.PostInfoBox>
    </S.ContentPostItem>
  );
};
