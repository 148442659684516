import * as S from "./MyPageForm.style";

interface MyPageFormProps {
  title: string;
  text: React.ReactNode;
  children?: React.ReactNode;
}

export const MyPageForm = ({ title, text, children }: MyPageFormProps) => {
  return (
    <S.MyPageForm>
      <S.FormTitBox>
        <S.FormTit>{title}</S.FormTit>
        <S.FormTxt>{text}</S.FormTxt>
      </S.FormTitBox>
      {children}
    </S.MyPageForm>
  );
};
