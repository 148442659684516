import styled from "styled-components";

export const SubLinkList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  li + li {
    &::before {
      position: absolute;
      content: "";
      height: 16px;
      left: 0;
      border-left: 1px solid ${(props) => props.theme.colors.black12};
    }
  }
`;
