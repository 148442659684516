import styled from "styled-components";

export const ContentPostItem = styled.li`
  display: flex;
  width: 220px;
  height: 120px;
  padding: 20px;
  background: ${(props) => props.theme.colors.white100};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadows.item};
  justify-content: space-between;
  flex-direction: column;
  flex-shrink: 0;
`;

export const PostTitBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const PostCategory = styled.span`
  color: ${(props) => props.theme.colors.black60};
  font-size: 1.2rem;
`;

export const PostTit = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
`;

export const PostInfoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PostInfo = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.black70};
  align-items: center;
  gap: 3px;

  svg {
    path {
      fill-opacity: 0.7;
    }
  }
`;
