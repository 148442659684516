export const profilAgeCalculater = (birthdateStr: string) => {
  // 문자열을 년, 월, 일로 분리
  const year = parseInt(birthdateStr.slice(0, 4), 10);
  const month = parseInt(birthdateStr.slice(4, 6), 10) - 1; // 월은 0부터 시작
  const day = parseInt(birthdateStr.slice(6, 8), 10);

  // 현재 날짜 가져오기
  const now = new Date();
  const birthDate = new Date(year, month, day);

  // 계산
  let years = now.getFullYear() - birthDate.getFullYear();
  let months = now.getMonth() - birthDate.getMonth();
  let days = now.getDate() - birthDate.getDate();

  // 날짜 계산 보정
  if (days < 0) {
    months -= 1;
    const previousMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    days += previousMonth.getDate(); // 이전 달의 일수 더하기
  }

  if (months < 0) {
    years -= 1;
    months += 12; // 음수 월 보정
  }

  // 결과 조합
  let result = "";
  if (years > 0) result += `${years}y `;
  if (months > 0 || years > 0) result += `${months}m `;
  result += `${days}d`;

  return result.trim(); // 불필요한 공백 제거
};
