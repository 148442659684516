import { Inputs, InputsProps } from "..";
import * as S from "./InputCheckbox.style";
import { ReactComponent as IconCheckbox } from "@/styles/assets/svg/icon_checkbox_checked.svg";

interface InputCheckboxProps extends InputsProps {
  name: string;
  data?: any;
}

export const InputCheckbox = ({ label, name, data }: InputCheckboxProps) => {
  return (
    <Inputs label={label && label}>
      <S.InputCheckbox>
        {data.map((item: any, idx: number) => {
          return (
            <S.CheckboxLabel key={idx}>
              <S.Checkbox>
                <IconCheckbox width={18} height={18} viewBox="3 3 18 18" />
                <input type="checkbox" name={name} />
              </S.Checkbox>
              {item.displayExpr}
            </S.CheckboxLabel>
          );
        })}
      </S.InputCheckbox>
    </Inputs>
  );
};
