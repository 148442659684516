import styled from "styled-components";

export const MainBanner = styled.div`
  position: relative;
  padding: 50px;
  background: ${(props) => props.theme.gradients.banner1};
  border-radius: 50px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    display: flex;
    width: 100%;
    padding: 30px;
    background: ${(props) => props.theme.gradients.banner2};
    border-radius: 20px;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
`;

export const BannerContent = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-shrink: 0;
  gap: 26px;
  z-index: 2;
`;

export const BannerTitBox = styled.div`
  .tablet-size {
    display: flex;
    flex-direction: column;
    gap: 20px;

    div {
      display: flex;
      font-size: 1.8rem;
      flex-direction: column;
      gap: 6px;

      span {
        font-size: 3.4rem;
        font-weight: 900;
      }
    }
  }

  .mobile-size {
    display: none;
    flex-direction: column;
    color: ${(props) => props.theme.colors.white100};
    font-size: 2rem;
    font-weight: 600;
    line-height: 140%;
    gap: 16px;
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    .tablet-size {
      display: none;
    }

    .mobile-size {
      display: flex;
    }
  }
`;

export const ImgButton = styled.div`
  .tablet-size {
  }

  .mobile-size {
    display: none;
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    .tablet-size {
      display: none;
    }

    .mobile-size {
      display: flex;
    }
  }
`;

export const ImgBanner = styled.div`
  position: absolute;
  right: 40px;
  bottom: 20px;
  z-index: 1;

  svg {
    width: 30vw;
    height: 40vh;
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    position: relative;
    width: 100%;
    right: unset;
    bottom: unset;

    svg {
      display: none;
    }
  }
`;

export const ImgProfile = styled.div`
  overflow: hidden;
  display: none;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.white100};
  border-radius: 50%;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    display: block;
  }
`;
