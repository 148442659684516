import styled from "styled-components";

export const ProfileImgFile = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
`;

export const ProfileImgBox = styled.div`
  position: relative;
  display: flex;
  width: 280px;
  height: 280px;
  padding: 10px;
  background: ${(props) => props.theme.colors.white100};
  box-shadow: ${(props) => props.theme.shadows.profile};
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 250px;
    height: 250px;

    path {
      fill: ${(props) => props.theme.colors.black12};
    }
  }
`;

export const ProfileAddIcon = styled.span`
  position: absolute;
  right: 6px;
  bottom: -6px;

  & > svg {
    background: ${(props) => props.theme.colors.white100};
    border-radius: 50%;

    path {
      fill: ${(props) => props.theme.colors.secondary100};
    }
  }
`;
