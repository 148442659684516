import { SubjectItem } from "@/components/atoms/SubjectItem";
import * as S from "./SubjectList.style";

interface SubjectListProps {
  data?: any;
  onTopicSelect: (topicId: string) => void;
  selectedTopic: string[];
}

export const SubjectList = ({
  data,
  onTopicSelect,
  selectedTopic,
}: SubjectListProps) => {
  return (
    <S.SubjectList>
      {data?.map((item: any) => {
        return (
          <SubjectItem
            data={item}
            key={item.id}
            onSelect={onTopicSelect}
            isSelected={selectedTopic.includes(item.id)}
          />
        );
      })}
    </S.SubjectList>
  );
};
