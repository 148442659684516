import * as S from "./JoinForm.style";

interface JoinFormProps {
  title?: string;
  text?: React.ReactNode;
  children?: React.ReactNode;
  formButton?: React.ReactNode;
  gridColumn?: string;
  onSubmit?: () => void;
}

export const JoinForm = ({
  title,
  text,
  children,
  formButton,
  gridColumn,
  onSubmit,
}: JoinFormProps) => {
  return (
    <S.JoinForm $formButton={formButton ? true : false} onSubmit={onSubmit}>
      <S.FormBox $formButton={formButton ? true : false}>
        {title && (
          <S.FormTitBox>
            <S.FormTit>{title}</S.FormTit>
            {text && <S.FormTxt>{text}</S.FormTxt>}
          </S.FormTitBox>
        )}
        <S.FormContent $gridColumn={gridColumn}>{children}</S.FormContent>
      </S.FormBox>
      {formButton && formButton}
    </S.JoinForm>
  );
};
