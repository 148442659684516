import * as S from "./TestItem.style";

interface TextItemProps {
  image?: any;
}

export const TestItem = ({ image }: TextItemProps) => {
  return (
    <S.TestItem>
      <img src={image} alt="추천 진단테스트 이미지" />
    </S.TestItem>
  );
};
