import * as S from "./InputProfileFile.style";

import { ReactComponent as IconUser } from "@/styles/assets/svg/icon_user.svg";
import { ReactComponent as IconPlus } from "@/styles/assets/svg/icon_plus.svg";

interface ProfileImgFileProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  imgUrl: any;
}

export const ProfileImgFile = ({ onChange, imgUrl }: ProfileImgFileProps) => {
  console.log("@@@ imgUrl: ", imgUrl);
  console.log(`${process.env.REACT_APP_API_URL}/${imgUrl?.path}`);
  return (
    <S.ProfileImgFile>
      <input
        type="file"
        id="profileImage"
        accept="image/*"
        onChange={onChange}
        style={{ display: "none" }}
      />
      <label htmlFor="profileImage">
        <S.ProfileImgBox>
          {imgUrl?.path ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/${imgUrl?.path}`}
              alt="profile"
              width={200}
              height={200}
              style={{
                borderRadius: "50%",
              }}
            />
          ) : (
            <IconUser />
          )}
          <S.ProfileAddIcon>
            <IconPlus width={60} height={60} />
          </S.ProfileAddIcon>
        </S.ProfileImgBox>
      </label>
    </S.ProfileImgFile>
  );
};
