import { useNavigate } from "react-router-dom";

import { Button } from "@/components/atoms/Buttons";
import { InputLogin } from "@/components/atoms/Inputs/InputLogin";
import { InputCheckbox } from "@/components/atoms/Inputs/InputCheckbox";
import { ButtonSet } from "@/components/molecules/ButtonSet";
import { InputButtonSet } from "@/components/molecules/InputButtonSet";
import { LoginForm } from "@/components/organisms/LoginForm";
import { LoginTemplate } from "@/components/templates/LoginTemplate";

import { ReactComponent as IconKakao } from "@/styles/assets/svg/icon_sns_kakao.svg";
import { ReactComponent as IconNaver } from "@/styles/assets/svg/icon_sns_naver.svg";
import { ReactComponent as IconApple } from "@/styles/assets/svg/icon_sns_apple.svg";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { request } from "@/lib/api";
import { ResponseType } from "@/types/response.types";
import useAuthToken from "@/lib/hooks/useAuthToken";
import { toast } from "react-toastify";
import { login } from "@/lib/flutterFuntions";

export interface LoginProps {
  user_id: string;
  password: string;
}

export const LoginPage = () => {
  /** ============================= state 영역 ============================= */
  const navigate = useNavigate();
  const { customLogin } = useAuthToken();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<LoginProps>();

  const saveIdForm = useForm();
  const saveId = saveIdForm.watch("saveId"); //
  /** ============================= API 영역 ============================= */
  const { mutateAsync: onLogin } = useMutation(
    (data: LoginProps) => {
      return request<ResponseType<any>>({
        method: "POST",
        url: `/auth/sign-in`,
        data: data,
      });
    },
    {
      onSuccess: async (res: any) => {
        if (res.success) {
          // 로그인 성공 정보 저장
          await customLogin(res.data);

          // // 쿠키에서 토큰 가져오기
          // const cookies = document.cookie.split(";");
          // const accessToken =
          //   cookies
          //     .find((c) =>
          //       c.trim().startsWith(`${process.env.ACCESS_TOKEN_NAME}=`)
          //     )
          //     ?.split("=")[1] || "";
          // const refreshToken =
          //   cookies
          //     .find((c) =>
          //       c.trim().startsWith(`${process.env.REFRESH_TOKEN_NAME}=`)
          //     )
          //     ?.split("=")[1] || "";

          // console.log("accessToken", accessToken);
          // console.log("refreshToken", refreshToken);

          // // 플러터 스토리지 로그인 정보 저장
          // login(accessToken, refreshToken);
        }
      },
    }
  );
  /** ============================= 비즈니스 로직 영역 ============================= */
  // 로그인 함수
  const onLoginHandler = async (data: LoginProps) => {
    try {
      await onLogin(data);
    } catch (error) {
      console.error(error);
    }
  };

  /** 아이디 저장 토글 함수 */
  const handleSaveIdToggle = () => {
    if (saveId) {
      localStorage.removeItem("savedUserId");
    } else {
      const currentId = watch("user_id");
      localStorage.setItem("savedUserId", currentId);
    }
  };
  /** ============================= 컴포넌트 영역 ============================= */

  /** ============================= useEffect 영역 ============================= */
  return (
    <LoginTemplate>
      <LoginForm
        onSubmit={handleSubmit(onLoginHandler)}
        formButton={
          <>
            <Button
              type="submit"
              // type="button"
              width="28vw"
              color="white100"
              layout="solid"
              size="sm"
              label="로그인"
            />
            <Button
              type="submit"
              width="28vw"
              color="white100"
              layout="outline"
              size="sm"
              label="아이디/비밀번호 찾기"
            />
          </>
        }
      >
        <InputLogin
          register={register("user_id")}
          type="text"
          label="아이디"
          placeholder="아이디를 입력해 주세요."
        />
        <InputLogin
          register={register("password")}
          type="password"
          label="비밀번호"
          placeholder="비밀번호를 입력해 주세요."
        />
        <InputButtonSet gap="26px">
          <InputCheckbox
            name="save_id"
            data={[{ displayExpr: "로그인 유지" }]}
          />
          <InputCheckbox
            name="save_id"
            data={[{ displayExpr: "자동 로그인" }]}
          />
        </InputButtonSet>
      </LoginForm>

      <ButtonSet label="SNS로 시작하기" direction="column">
        <Button
          type="button"
          width="60px"
          size="md"
          layout="solid"
          color="kakao"
          icon={<IconKakao width={30} height={30} />}
        />
        <Button
          type="button"
          width="60px"
          size="md"
          layout="solid"
          color="naver"
          icon={<IconNaver width={30} height={30} />}
        />
        <Button
          type="button"
          width="60px"
          size="md"
          layout="solid"
          color="apple"
          icon={<IconApple width={30} height={30} />}
        />
      </ButtonSet>
    </LoginTemplate>
  );
};
