import { useNavigate } from "react-router-dom";

import { userIcon } from "@/data/user";

import { Button } from "@/components/atoms/Buttons";
import { InputText } from "@/components/atoms/Inputs/InputText/indext";
import { InputRadio } from "@/components/atoms/Inputs/InputRadio";
import { Form } from "@/components/molecules/Form";
import { JoinForm } from "@/components/organisms/JoinForm";
import { JoinTemplate } from "@/components/templates/JoinTemplate";
import { InputProfileIcon } from "@/components/atoms/Inputs/InputProfileIcon";
import { ProfileImgFile } from "@/components/molecules/ProfileImgFile";
import { useMutation } from "@tanstack/react-query";
import { request } from "@/lib/api";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ResponseType } from "@/types/response.types";

export const ProfileFormPage = () => {
  /** ============================= state 영역 ============================= */
  const navigate = useNavigate();
  /** 프로필 추가 메인  useForm  */
  const profileForm = useForm<any>({});
  const { register, handleSubmit } = profileForm;

  const [isUploading, setIsUploading] = useState(false);
  const [imgUrl, setImgUrl] = useState<any>({});

  /** ============================= API 영역 ============================= */
  // 사진추가 버튼 클릭시 실행 함수
  const { mutateAsync: ImageUpload } = useMutation({
    mutationFn: (data: FormData) => {
      return request({
        method: "POST",
        url: "/profile/image/upload",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    onError: () => {
      toast.error("이미지 업로드에 실패했습니다.");
    },
  });

  /** 프로필 저장 api */
  const { mutateAsync: onSaveProfile } = useMutation(
    ["ONSAVEPROFILE"],
    (profileData: any) =>
      request<ResponseType<any>>({
        method: "POST",
        url: `/profile/create`,
        data: profileData,
      }),
    {
      onSuccess(data: ResponseType<any>) {
        if (data.success) {
          toast.success("프로필이 성공적으로 저장되었습니다.");
          navigate("/auth/profile/select");
        }
      },
    }
  );

  /** ============================= 비즈니스 로직 영역 ============================= */
  /** 이미지 업로드시 이미지 변경 함수
   *  이미지 업로드 후 반환된 이미지 정보를 배열에 저장하는 함수 ( 항상 0번 인덱스만 열람 )
   */
  const onChangeImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    try {
      const imgFile = e.target.files;

      if (imgFile !== undefined) {
        setIsUploading(true);
        const formData = new FormData();

        // 이미지 압축 옵션!!
        const options = {
          maxSizeMB: 0.4,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        for (let i = 0; i < imgFile.length; i++) {
          const file = imgFile[i];
          const fileExtension = file.name.split(".").pop();
          const compressedFile = await imageCompression(file, options);

          // 압축된 파일에 확장자 추가
          const compressedFileWithExt = new File(
            [compressedFile],
            `${compressedFile.name}.${fileExtension}`,
            { type: compressedFile.type }
          );

          formData.append("files", compressedFileWithExt);
        }

        const result: any = await ImageUpload(formData);

        for (let i = 0; i < result.length; i++) {
          let newImage = {
            originalname: result[i]?.originalname,
            mimetype: result[i]?.mimetype,
            size: result[i]?.size,
            path: `${result[i]?.filename}`,
            updatedAt: new Date(),
          };

          setImgUrl(newImage);
          setIsUploading(false);
        }
      }
    } catch (error) {
      console.log("@@@ ERROR: ", error);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const dataSource = {
        ...data,
        profile_file_info:
          Object.keys(imgUrl).length === 0
            ? null
            : {
                ...imgUrl,
              },
      };

      await onSaveProfile(dataSource);
    } catch (error: any) {
      console.log("error: ", error);
      // toast.error(error.message || "프로필 저장 중 오류가 발생했습니다.");
    }
  };
  /** ============================= 컴포넌트 영역 ============================= */

  /** ============================= useEffect 영역 ============================= */
  return (
    <JoinTemplate>
      <JoinForm
        onSubmit={handleSubmit(onSubmit)}
        title="프로필 추가하기"
        gridColumn="repeat(2, 1fr)"
        text="프로필 작성은 실 사용자에 대한 정보를 입력해주세요."
        formButton={
          <Button
            type="submit"
            width="100vw"
            size="full"
            color="primary100"
            layout="solid"
            label="프로필 저장"
          />
        }
      >
        {/* 프로필 사진 설정 */}
        <Form title="프로필 사진 설정" text={<>프로필 사진을 등록해 주세요.</>}>
          <ProfileImgFile onChange={onChangeImg} imgUrl={imgUrl} />
        </Form>

        {/* 프로필 정보 입력 */}
        <Form title="프로필 정보 입력">
          <InputText
            label="이름"
            placeholder="이름을 입력해 주세요."
            register={register("name")}
            required={true}
          />
          <InputText
            label="생년월일"
            placeholder="생년월일 8자리 입력(예: 20241030)."
            register={register("birth_date")}
            required={true}
            maxLength={8}
          />
          <InputRadio
            label="성별"
            name="gender"
            data={[
              { value: "male", displayExpr: "남" },
              { value: "female", displayExpr: "여" },
            ]}
            onChange={profileForm?.setValue}
            inputForm={profileForm && profileForm}
            required={true}
          />
          <InputProfileIcon
            label="아이콘 선택"
            name="icon"
            data={userIcon}
            onChange={profileForm?.setValue}
            required={true}
          />
        </Form>
      </JoinForm>
    </JoinTemplate>
  );
};
