import { request } from "@/lib/api";
import { userInfoSelector } from "@/recoil/userInfo";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { deviceTypeState } from "@/recoil/deviceType";

export const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useRecoilState(userInfoSelector);
  const [deviceType, setDeviceType] = useRecoilState(deviceTypeState);

  const navigate = useNavigate();
  const location = useLocation();
  const [accessToken, setAccessToken, removeAccessToken] = useCookies([
    "ggp_accessToken",
  ]);
  const [refreshToken, setRefreshToken, removeRefreshToken] = useCookies([
    "ggp_refreshToken",
  ]);

  // 인증이 필요없는 페이지 목록
  const publicPaths = [
    "/auth/login",
    "/auth/join/form",
    "/auth/join/select",
    "/auth/join/buy",
    "/auth/profile/select",
    "/auth/profile/form",
  ];

  useEffect(() => {
    const checkDeviceType = () => {
      // iPad와 태블릿은 일반적으로 768px 이상의 화면 너비를 가짐
      const isTablet = window.innerWidth >= 768;
      setDeviceType(isTablet ? "pad" : "phone");
    };

    checkDeviceType();
    window.addEventListener("resize", checkDeviceType);

    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  useEffect(() => {
    // 현재 경로가 public path인 경우 인증 체크 건너뛰기
    if (publicPaths.includes(location.pathname)) {
      return;
    }

    // 토큰이 없고 public path가 아닌 경우에만 로그인 페이지로 이동
    if (!accessToken && !refreshToken) {
      navigate("/auth/login");
      return;
    }

    const fetchUser = async () => {
      try {
        const response: any = await request({
          method: "GET",
          url: "/auth/info",
        });

        if (response.length === 0) {
          if (!publicPaths.includes(location.pathname)) {
            navigate("/auth/login");
          }
        }
        setUser(response.data);
      } catch (error) {
        console.error("사용자 정보 가져오기 실패:", error);
        if (!publicPaths.includes(location.pathname)) {
          navigate("/auth/login");
        }
      }
    };

    fetchUser();
  }, [navigate, location.pathname]);

  if (user === null && !publicPaths.includes(location.pathname)) {
    return <div>로딩 중...</div>;
  }

  return <>{children}</>;
};
