import { useNavigate } from "react-router-dom";

import * as S from "./ButtonGrowLearn.style";

import { ReactComponent as IconArrow } from "@/styles/assets/svg/icon_arrow_circle.svg";
import { ReactComponent as ImgLearning } from "@/styles/assets/svg/img_login.svg";

export const ButtonGrowLearn = () => {
  const navigate = useNavigate();
  return (
    <S.ButtonGrowLearn type="button" onClick={() => navigate("/mypage/select")}>
      <S.ButtonTit>
        그로우러닝 맞춤 분석
        <p>
          우리아이 맞춤분석 내용을
          <br />
          확인해 보세요!
        </p>
      </S.ButtonTit>

      <IconArrow />

      <S.ButtonImg>
        <ImgLearning width={130} height={100} />
      </S.ButtonImg>
    </S.ButtonGrowLearn>
  );
};
