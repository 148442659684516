import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  shadows: {
    profile: "0px 2px 8px -2px rgba(0,0,0,.1)",
    banner: "0px 2px 10px -4px rgba(0,0,0,.16)",
    item: "0px 2px 10px -4px rgba(0,0,0,0.1)",
    nav: "0px -2px 10px -4px rgba(0,0,0,.08)",
  },
  gradients: {
    banner1: "linear-gradient(#FFD941 0%, #FFD62E 8%, #FFCD11 100%)",
    banner2:
      "linear-gradient(#EE8600 0%, #EE8800 12%, #EE8000 23%, #EE7E00 39%, #F26700 100%)",
    growLearn: "linear-gradient(135deg, #F58415 0%, #FAA514 73%, #FEC01A 100%)",
  },
  colors: {
    // Colors
    primary100: "#FFCD11",
    primary100Hover: "#FFBF11",
    secondary100: "#EE8000",
    secondary100Hover: "#EE7500",

    // Basics
    white100: "#ffffff",
    white100Hover: "#FFF9E4",
    white80: "#ffffffcc",
    white70: "#ffffffb3",
    white60: "#ffffff99",
    white38: "#ffffff61",
    white12: "#ffffff1f",
    black100: "#000000",
    black90: "#000000e6",
    black80: "#000000cc",
    black70: "#000000b3",
    black60: "#00000099",
    black50: "#00000080",
    black38: "#00000061",
    black30: "#0000004C",
    black12: "#0000001f",
    black10: "#00000019",
    black8: "#00000014",
    black5: "#0000000d",
    black4: "#00000004",
    black2: "#00000005",
    gray100: "#CCCCCC",
    red100: "#EA3518",
    red100Hover: "#D63319",
    deepRed100: "#C62235",
    deepRed100Hover: "#B51528",
    green100: "#008F41",
    skyblue100: "#00B3EC",
    blue100: "#004F98",
    destructive100: "#D74E4E",
    yellowBg: "#FFFBF1",
    blueBg: "#F2FCFF",
    orangeBg: "#FFF8F0",

    // SNS
    kakao: "#F9E000",
    kakaoHover: "#F9D200",
    naver: "#03CF5D",
    naverHover: "#1EC065",
    apple: "#181818",
    appleHover: "#000000",

    // Scrollbar
    scrollbar: "#0000004c",

    // Component
    profileBg: "#F7F6F2",
  },
};
