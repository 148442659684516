import styled from "styled-components";

export const GrowProfileItem = styled.li`
  display: flex;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  box-shadow: ${(props) => props.theme.shadows.item};
  align-items: center;
  justify-content: space-between;
`;

export const UserBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;

  button {
    color: ${(props) => props.theme.colors.white100};
  }
`;

export const UserInfoBox = styled.div`
  display: flex;
  padding: 4px;
  flex-direction: column;
  gap: 8px;

  & > span {
    font-size: 1.4rem;
    font-weight: 800;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.black60};
  font-size: 1.4rem;
  align-items: center;
  gap: 6px;
`;

export const UserName = styled.div`
  color: ${(props) => props.theme.colors.black100};
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 0.2rem;
`;

export const UserImg = styled.div`
  width: 110px;
  height: 110px;
  padding: 5px;
  background: ${(props) => props.theme.colors.white100};
  box-shadow: ${(props) => props.theme.shadows.profile};
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
`;

export const UserImgInput = styled.label`
  position: relative;

  & > svg {
    path {
      fill: ${(props) => props.theme.colors.black38};
    }
  }

  & > span {
    position: absolute;
    right: -6px;
    bottom: -6px;
    background: ${(props) => props.theme.colors.white100};
    border-radius: 50%;

    svg {
      path {
        fill: ${(props) => props.theme.colors.secondary100};
      }
    }
  }

  input {
    display: none;
  }
`;
