import styled, { css } from "styled-components";

interface InputSetStyleProps {
  $direction: "row" | "column";
  $gridColumn?: string;
  $gap: string;
}

export const InputSet = styled.div<InputSetStyleProps>`
  display: ${(props) => (props.$gridColumn ? "grid" : "flex")};
  width: 100%;
  flex-direction: ${(props) => props.$direction};
  align-items: end;
  gap: ${(props) => props.$gap};

  ${(props) =>
    props.$gridColumn &&
    css`
      grid-template-columns: ${props.$gridColumn};
    `}

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    & > div {
      width: 100%;
    }
  }
`;
