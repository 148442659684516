import styled from "styled-components";

export const LinkListBox = styled.div`
  display: flex;
  width: calc(100vw - 32px);
  flex-direction: column;
  gap: 10px;
`;

export const LinkListTit = styled.div`
  padding: 0 6px;
  font-size: 1.6rem;
  font-weight: 700;
`;

export const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
`;
