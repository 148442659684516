import styled from "styled-components";

export const InputProfileIcon = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 0;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`;

export const CheckIcon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.2s ease;

  svg {
    path {
      fill: ${(props) => props.theme.colors.secondary100};
    }
  }
`;

export const ProfileIconLabel = styled.label`
  position: relative;
  display: flex;
  width: 62px;
  height: 62px;
  color: ${(props) => props.theme.colors.black38};
  font-size: 1.6rem;
  font-weight: 700;
  background: ${(props) => props.theme.colors.white100};
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  input {
    display: none;
  }

  &:has(input:checked) {
    color: ${(props) => props.theme.colors.white100};
    border: 1px solid ${(props) => props.theme.colors.secondary100};
    box-shadow: ${(props) => props.theme.shadows.item};

    ${CheckIcon} {
      opacity: 1;
    }
  }
`;
