import styled from "styled-components";

export const MainTemplate = styled.div`
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-rows: 80px auto 100px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    grid-template-rows: 56px auto 70px;
  }
`;

export const MainContentBox = styled.div`
  display: grid;
  width: 100vw;
  height: calc(100vh - 180px);
  padding: 24px;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    overflow-y: auto;
    display: flex;
    padding: 16px;
    height: calc(100vh - 126px);
    flex-direction: column;
    gap: 30px;
  }
`;
