import { UseFormRegister } from "react-hook-form";
import { Inputs, InputsProps } from "..";
import { Button } from "../../Buttons";
import * as S from "./InputAddress.style";

interface InputAddressProps extends InputsProps {
  openPost?: () => void;
}

export const InputAddress = ({
  label,
  placeholder,
  register,
  openPost,
  required,
}: InputAddressProps) => {
  return (
    <Inputs label={label && label} required={required}>
      <S.InputAddress>
        <S.InputPostalCode>
          <input
            type="text"
            placeholder="우편번호 입력"
            disabled
            {...register?.("zip_code")}
          />
          <Button
            type="button"
            width="130px"
            size="input"
            color="secondary100"
            layout="outline"
            label="우편번호 검색"
            onClick={openPost}
          />
        </S.InputPostalCode>
        <input
          type="text"
          placeholder="주소 입력"
          disabled
          {...register?.("address")}
        />
        <input
          type="text"
          placeholder={placeholder}
          {...register?.("address_detail")}
        />
      </S.InputAddress>
    </Inputs>
  );
};
