import styled from "styled-components";

export const IntroTemplate = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.primary100};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    gap: 20px;
  }
`;

export const IntroLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  span {
    color: ${(props) => props.theme.colors.white100};
    font-size: 1.4rem;
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    margin-bottom: 4px;

    span {
      font-size: 1.2rem;
    }

    & > svg {
      height: 50px;
    }
  }
`;

export const IntroTop = styled.div`
  display: flex;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.white100};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
`;

export const IntroTopTit = styled.h2`
  font-size: 4.4rem;
  font-weight: 900;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    font-size: 3.2rem;
  }
`;

export const IntroTopText = styled.p`
  font-size: 1.8rem;
  text-align: center;
  line-height: 160%;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    font-size: 1.6rem;
    line-height: 150%;
  }
`;

export const IntroBottom = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;
