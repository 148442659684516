import { ProfileCardItem } from "@/components/atoms/ProfileCardItem";
import * as S from "./ProfileList.style";

interface ProfileListProps {
  data?: any;
  onClick?: any;
  selectedProfile?: any;
}

export const ProfileList = ({
  data,
  onClick,
  selectedProfile,
}: ProfileListProps) => {
  return (
    <S.ProfileList>
      {data?.map((item: any, idx: number) => {
        return (
          <ProfileCardItem
            data={item}
            key={idx}
            onClick={onClick}
            selectedProfile={selectedProfile}
          />
        );
      })}
      <ProfileCardItem />
    </S.ProfileList>
  );
};
