import { createGlobalStyle } from "styled-components";
import NanumSquareNeoWOFF2Light from "./woff2/NanumSquareNeoTTF-aLt.woff2";
import NanumSquareNeoWOFF2Regular from "./woff2/NanumSquareNeoTTF-bRg.woff2";
import NanumSquareNeoWOFF2Bold from "./woff2/NanumSquareNeoTTF-cBd.woff2";
import NanumSquareNeoWOFF2ExtraBold from "./woff2/NanumSquareNeoTTF-dEb.woff2";
import NanumSquareNeoWOFF2Heavy from "./woff2/NanumSquareNeoTTF-eHv.woff2";
import NanumSquareNeoWOFFLight from "./woff/NanumSquareNeoTTF-aLt.woff";
import NanumSquareNeoWOFFRegular from "./woff/NanumSquareNeoTTF-bRg.woff";
import NanumSquareNeoWOFFBold from "./woff/NanumSquareNeoTTF-cBd.woff";
import NanumSquareNeoWOFFExtraBold from "./woff/NanumSquareNeoTTF-dEb.woff";
import NanumSquareNeoWOFFHeavy from "./woff/NanumSquareNeoTTF-eHv.woff";

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'NanumSquareNeo';
    font-weight: 300;
    font-style: normal;
    src: local('NanumSquareNeo Light'),
         url(${NanumSquareNeoWOFF2Light}) format('woff2'),
         url(${NanumSquareNeoWOFFLight}) format('woff');
    font-display: FALLBACK;
  } 

  @font-face {
    font-family: 'NanumSquareNeo';
    font-weight: 400;
    font-style: normal;
    src: local('NanumSquareNeo Regular'),
         url(${NanumSquareNeoWOFF2Regular}) format('woff2'),
         url(${NanumSquareNeoWOFFRegular}) format('woff');
    font-display: FALLBACK;
  } 

  @font-face {
    font-family: 'NanumSquareNeo';
    font-weight: 700;
    font-style: normal;
    src: local('NanumSquareNeo Bold'),
         url(${NanumSquareNeoWOFF2Bold}) format('woff2'),
         url(${NanumSquareNeoWOFFBold}) format('woff');
    font-display: FALLBACK;
  } 

  @font-face {
    font-family: 'NanumSquareNeo';
    font-weight: 800;
    font-style: normal;
    src: local('NanumSquareNeo ExtraBold'),
         url(${NanumSquareNeoWOFF2ExtraBold}) format('woff2'),
         url(${NanumSquareNeoWOFFExtraBold}) format('woff');
    font-display: FALLBACK;
  } 

  @font-face {
    font-family: 'NanumSquareNeo';
    font-weight: 900;
    font-style: normal;
    src: local('NanumSquareNeo Heavy'),
         url(${NanumSquareNeoWOFF2Heavy}) format('woff2'),
         url(${NanumSquareNeoWOFFHeavy}) format('woff');
    font-display: FALLBACK;
  } 
`;
