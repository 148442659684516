import { ReactComponent as IconMenu01 } from "@/styles/assets/svg/icon_menu01_filled.svg";
import { ReactComponent as IconMenu02 } from "@/styles/assets/svg/icon_menu02_filled.svg";
import { ReactComponent as IconMenu03 } from "@/styles/assets/svg/icon_menu03_filled.svg";
import { ReactComponent as IconMenu04 } from "@/styles/assets/svg/icon_menu04_filled.svg";
import { ReactComponent as IconMenu05 } from "@/styles/assets/svg/icon_menu05_filled.svg";

/**
 * app : 앱 화면으로 이동 하는 메뉴인지 여부
 * appPath : 앱 화면으로 이동 하는 경우 이동 할 경로
 * isUse : 메뉴 사용 여부( false 일 경우 메뉴 클릭 시 아무 동작 없음 )
 */
export const menus = [
  {
    id: 1,
    menuTitle: "홈",
    icon: <IconMenu01 width={34} height={34} />,
    path: "/home",
    app: false,
    isUse: true,
  },
  {
    id: 2,
    menuTitle: "그로우러닝",
    icon: <IconMenu02 width={34} height={34} />,
    path: "/learn",
    app: true,
    isUse: true,
  },
  {
    id: 3,
    menuTitle: "진단테스트",
    icon: <IconMenu03 width={34} height={34} />,
    path: "/exam",
    app: false,
    isUse: false,
  },
  {
    id: 4,
    menuTitle: "커뮤니티",
    icon: <IconMenu04 width={34} height={34} />,
    path: "/community",
    app: false,
    isUse: false,
  },
  {
    id: 5,
    menuTitle: "마이페이지",
    icon: <IconMenu05 width={34} height={34} />,
    path: "/mypage",
    app: false,
    isUse: true,
  },
];
