import { ChildrenProfile } from "@/types/children_profile.types";

import { Button } from "@/components/atoms/Buttons";
import { ButtonUserProfile } from "@/components/atoms/Buttons/ButtonUserProfile";

import * as S from "./Header.style";

import { ReactComponent as IconLogo } from "@/styles/assets/svg/logo_only.svg";
import { ReactComponent as IconAlarm } from "@/styles/assets/svg/icon_alarm_filled.svg";
import { ReactComponent as IconSetting } from "@/styles/assets/svg/icon_setting_outlined.svg";

interface HeaderProps {
  setPopover?: any;
  selectedProfile?: ChildrenProfile;
}

export const Header = ({ setPopover, selectedProfile }: HeaderProps) => {
  return (
    <>
      <S.Header>
        <S.HeaderLeftBox></S.HeaderLeftBox>
        <S.HeaderTitBox>
          <IconLogo width={60} height={20} />
        </S.HeaderTitBox>
        <S.HeaderRightBox>
          <S.AlarmButton type="button">
            <IconAlarm />
          </S.AlarmButton>
          {/* <Button
            type="button"
            size="xsm"
            width="40px"
            layout="icon"
            color="gray100"
            icon={<IconSetting />}
          /> */}
          <ButtonUserProfile
            onClick={() => setPopover(true)}
            selectedProfile={selectedProfile}
          />
        </S.HeaderRightBox>
      </S.Header>
    </>
  );
};
