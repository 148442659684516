import styled, { css } from "styled-components";

interface NavItemStyleProps {
  $active?: boolean;
}

export const NavItem = styled.li`
  width: 100%;
`;

export const NavLink = styled.div<NavItemStyleProps>`
  display: flex;
  width: 100%;
  height: 100px;
  color: ${(props) => props.theme.colors.white100};
  // color: ${(props) => props.theme.colors.black60};
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  svg {
    path {
      fill: ${(props) => props.theme.colors.white100};
      // fill: ${(props) => props.theme.colors.black12};
    }
  }

  &:active {
    background: ${(props) => props.theme.colors.primary100Hover};
  }

  ${(props) =>
    props.$active === true &&
    css`
      background: ${props.theme.colors.secondary100};
      // background: ${props.theme.colors.primary100Hover};
      // color: ${props.theme.colors.white100};

      // svg {
      //   path {
      //     fill: ${props.theme.colors.white100};
      //   }
      // }
    `}

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    height: 70px;
    font-size: 1rem;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
