import styled from "styled-components";

export const HeaderPopover = styled.div`
  position: absolute;
  display: flex;
  top: 56px;
  right: 6px;
  padding: 6px 0;
  background: ${(props) => props.theme.colors.white100};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadows.profile};
  z-index: 101;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    top: 56px;
  }
`;

export const PopoverList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PopoverItem = styled.li`
  display: flex;
  height: 46px;
  padding: 0 20px;
  color: ${(props) => props.theme.colors.black60};
  font-size: 1.6rem;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  svg {
    path {
      fill: ${(props) => props.theme.colors.black38};
    }
  }

  &:active {
    color: ${(props) => props.theme.colors.black100};
    background: ${(props) => props.theme.colors.black5};

    svg {
      path {
        fill: ${(props) => props.theme.colors.secondary100};
      }
    }
  }
`;
