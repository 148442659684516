import { InputsProps } from "..";
import * as S from "./InputLogin.style";

interface InputLoginProps extends InputsProps {}

export const InputLogin = ({
  type,
  label,
  placeholder,
  register,
}: InputLoginProps) => {
  return (
    <S.InputLogin>
      {label && <S.InputLabel>{label}</S.InputLabel>}
      <S.Input type={type} placeholder={placeholder} {...register} />
    </S.InputLogin>
  );
};
