import React, { useEffect } from "react";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "styled-components";
import { Routes } from "./core/Routes";
import { GlobalStyleProvider } from "./core/GlobalStyleProvider";
import { theme } from "./styles/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ErrorBoundary from "./error/errorBoundary";
import { QueryClientProvider } from "@tanstack/react-query";
import { QueryClient } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AuthGuard } from "./core/AuthGuard";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <GlobalStyleProvider>
          <ErrorBoundary>
            <AuthGuard>
              <ThemeProvider theme={theme}>
                <Routes />
                <ToastContainer autoClose={1000} />
              </ThemeProvider>
            </AuthGuard>
          </ErrorBoundary>
        </GlobalStyleProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
