import styled, { css } from "styled-components";

interface ButtonStyleProps {
  $size: "xsm" | "sm" | "md" | "lg" | "full" | "input";
  $width?: string;
  $color:
    | "primary100"
    | "secondary100"
    | "red100"
    | "deepRed100"
    | "white100"
    | "gray100"
    | "kakao"
    | "naver"
    | "apple";
  $layout: "solid" | "outline" | "text" | "icon";
}

export const Buttons = styled.button<ButtonStyleProps>`
  display: flex;
  width: ${(props) => (props.$width ? props.$width : "auto")};
  transition: all 0.2s ease;
  align-items: center;
  justify-content: center;

  // 사이즈별
  ${(props) =>
    props.$size === "input" &&
    css`
      height: 48px;
      padding: ${props.$width ? "0px" : "0 10px"};
      font-size: 1.6rem;
      font-weight: 700;
      border-radius: 10px;
    `}

  ${(props) =>
    props.$size === "xsm" &&
    css`
      height: 40px;
      padding: ${props.$width ? "0px" : "0 10px"};
      font-size: 1.6rem;
      font-weight: 700;
      border-radius: 10px;
    `}

  ${(props) =>
    props.$size === "sm" &&
    css`
      height: 50px;
      padding: ${props.$width ? "0px" : "0 30px"};
      font-size: 1.8rem;
      font-weight: 700;
      border-radius: 25px;

      // 모바일 환경(600px 이하) 설정
      @media (max-width: 600px) {
        height: 46px;
        font-size: 1.6rem;
      }
    `}
  
  ${(props) =>
    props.$size === "md" &&
    css`
      height: 60px;
      padding: ${props.$width ? "0px" : "0 50px"};
      font-size: 2rem;
      font-weight: 700;
      border-radius: 30px;
    `}

  ${(props) =>
    props.$size === "lg" &&
    css`
      height: 76px;
      padding: ${props.$width ? "0px" : "0 50px"};
      font-size: 2rem;
      font-weight: 800;
      border-radius: 38px;
    `}
  
  ${(props) =>
    props.$size === "full" &&
    css`
      height: 80px;
      width: ${props.$width ? props.$width : "100"};
      padding: ${props.$width ? "0px" : "0 50px"};
      font-size: 2rem;
      font-weight: 700;

      // 모바일 환경(600px 이하) 설정
      @media (max-width: 600px) {
        height: 60px;
        font-size: 1.8rem;
      }
    `}

  // 레이아웃별
  ${(props) =>
    props.$layout === "solid" &&
    css`
      color: ${props.$color === "white100"
        ? props.theme.colors.secondary100
        : props.$color === "primary100"
        ? props.theme.colors.black100
        : props.theme.colors.white100};
      border: none;
      background: ${props.theme.colors[props.$color]};

      &:active {
        background: ${props.theme.colors[props.$color + "Hover"]};
      }
    `}

  ${(props) =>
    props.$layout === "outline" &&
    css`
      color: ${props.theme.colors[props.$color]};
      border: 1px solid ${props.theme.colors[props.$color]};
      background: none;

      &:active {
        background: ${props.theme.colors.black10};
      }
    `}

  ${(props) =>
    props.$layout === "text" &&
    css`
      color: ${props.theme.colors[props.$color]};
      border: none;
      background: none;

      &:active {
        background: ${props.theme.colors.black10};
      }
    `}

      ${(props) =>
    props.$layout === "icon" &&
    css`
      display: flex;
      color: ${props.theme.colors[props.$color]};
      border: none;
      background: none;
      align-items: center;
      justify-content: center;

      &:active {
        background: ${props.theme.colors.black10};
      }
    `}
`;
