import { UserInfoBox } from "@/components/atoms/GrowProfileItem/GrowProfileItem.style";
import styled from "styled-components";

export const GrowProfileList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;

  li {
    &:nth-of-type(3n) {
      background: ${(props) => props.theme.colors.orangeBg};
      border: 1px solid ${(props) => props.theme.colors.secondary100};

      button {
        background: ${(props) => props.theme.colors.secondary100};
      }

      ${UserInfoBox} {
        span {
          color: ${(props) => props.theme.colors.secondary100} !important;
        }
      }
    }

    &:nth-of-type(3n-1) {
      background: ${(props) => props.theme.colors.blueBg};
      border: 1px solid ${(props) => props.theme.colors.skyblue100};

      button {
        background: ${(props) => props.theme.colors.skyblue100};
      }

      ${UserInfoBox} {
        span {
          color: ${(props) => props.theme.colors.skyblue100} !important;
        }
      }
    }

    &:nth-of-type(3n-2) {
      background: ${(props) => props.theme.colors.yellowBg};
      border: 1px solid ${(props) => props.theme.colors.primary100};

      button {
        background: ${(props) => props.theme.colors.primary100};
      }

      ${UserInfoBox} {
        span {
          color: ${(props) => props.theme.colors.primary100} !important;
        }
      }
    }
  }
`;
