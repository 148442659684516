import * as S from "./ButtonSet.style";

interface ButtonSetProps {
  direction?: "column" | "row";
  label?: string;
  children?: React.ReactNode;
}

export const ButtonSet = ({
  direction = "row",
  label,
  children,
}: ButtonSetProps) => {
  return (
    <S.ButtonSet $direction={direction}>
      {label && <S.ButtonSetTit>{label}</S.ButtonSetTit>}

      <S.ButtonSetContent>{children}</S.ButtonSetContent>
    </S.ButtonSet>
  );
};
