import styled from "styled-components";

export const TestItem = styled.li`
  width: 110px;
  height: 150px;
  box-shadow: ${(props) => props.theme.shadows.item};
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
`;
