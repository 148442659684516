import * as S from "./Buttons.style";

interface ButtonProps {
  type: "submit" | "button" | "reset" | undefined;
  icon?: React.ReactNode;
  label?: string;
  children?: React.ReactNode;
  onClick?: any;
  disabled?: boolean;
  size: "xsm" | "sm" | "md" | "lg" | "full" | "input";
  width?: string;
  color:
    | "primary100"
    | "secondary100"
    | "red100"
    | "deepRed100"
    | "white100"
    | "gray100"
    | "kakao"
    | "naver"
    | "apple";
  layout: "solid" | "outline" | "text" | "icon";
}

export const Button = ({
  type,
  disabled = false,
  icon,
  label,
  children,
  onClick,
  size,
  width,
  color,
  layout,
}: ButtonProps) => {
  return (
    <S.Buttons
      type={type}
      disabled={disabled}
      onClick={onClick}
      $width={width}
      $size={size}
      $color={color}
      $layout={layout}
    >
      {children && children}
      {label && label}
      {icon && icon}
    </S.Buttons>
  );
};
