import { LinkItem } from "@/components/atoms/LinkItem";
import * as S from "./LinkList.style";

interface LinkListProps {
  title?: string;
  data?: any;
}

export const LinkList = ({ title, data }: LinkListProps) => {
  return (
    <S.LinkListBox>
      <S.LinkListTit>{title}</S.LinkListTit>
      <S.LinkList>
        {data.map((item: any, idx: number) => {
          return <LinkItem key={idx} data={item} />;
        })}
      </S.LinkList>
    </S.LinkListBox>
  );
};
