import styled from "styled-components";

export const JoinTemplate = styled.div`
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-rows: 80px calc(100vh - 80px);

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    grid-template-rows: 56px calc(100vh - 56px);
  }
`;

export const JoinHeader = styled.header<{ backButton: boolean }>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    padding: 0 4px;

    button {
      svg {
        width: 20px;
        height: 20px;
      }
    }

    & > svg {
      width: auto;
      height: 24px;
    }
  }
`;

export const JoinHeaderRight = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
`;

export const JoinContent = styled.section`
  overflow: hidden;
  height: calc(100vh - 80px);

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    height: calc(100vh - 56px);
  }
`;
