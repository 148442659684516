import { GrowProfileItem } from "@/components/atoms/GrowProfileItem";
import * as S from "./GrowProfileList.style";

interface GrowProfileListProps {
  data?: any;
}

export const GrowProfileList = ({ data }: GrowProfileListProps) => {
  return (
    <S.GrowProfileList>
      {data.map((item: any, idx: number) => {
        return <GrowProfileItem key={idx} data={item} />;
      })}
    </S.GrowProfileList>
  );
};
