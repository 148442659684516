import { useNavigate } from "react-router-dom";
import { Button } from "@/components/atoms/Buttons";
import * as S from "./JoinTemplate.style";
import { ReactComponent as IconBack } from "@/styles/assets/svg/icon_back.svg";
import { ReactComponent as IconLogo } from "@/styles/assets/svg/logo_only.svg";
import { navigatorPopScreen } from "@/lib/flutterFuntions";
interface JoinTemplateProps {
  children?: React.ReactNode;
  backButton?: boolean;
}

export const JoinTemplate = ({
  children,
  backButton = true,
}: JoinTemplateProps) => {
  const navigate = useNavigate();

  return (
    <S.JoinTemplate>
      <S.JoinHeader backButton={backButton}>
        {backButton && (
          <Button
            type="button"
            width="50px"
            color="gray100"
            layout="icon"
            size="sm"
            icon={<IconBack />}
            onClick={() => navigatorPopScreen()}
          />
        )}
        <IconLogo height={36} />
        <S.JoinHeaderRight></S.JoinHeaderRight>
      </S.JoinHeader>

      <S.JoinContent>{children}</S.JoinContent>
    </S.JoinTemplate>
  );
};
