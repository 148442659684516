import * as S from "./ImgBanner.style";

interface ImgBannerProps {
  height?: number;
  img: any;
}

export const ImgBanner = ({ height, img }: ImgBannerProps) => {
  return (
    <S.ImgBanner $height={height}>
      <img src={img} alt="배너 이미지" />
    </S.ImgBanner>
  );
};
