import * as S from "./Form.style";

interface FormProps {
  title?: string;
  text?: React.ReactNode;
  children?: React.ReactNode;
}

export const Form = ({ title, text, children }: FormProps) => {
  return (
    <S.Form>
      {title && (
        <S.FormTitBox>
          <S.FormTit>{title}</S.FormTit>
          {text && <S.FormTxt>{text}</S.FormTxt>}
        </S.FormTitBox>
      )}
      <S.FormRowBox>{children}</S.FormRowBox>
    </S.Form>
  );
};
