import * as S from "./InputSet.style";

interface InputSetProps {
  direction?: "row" | "column";
  children?: React.ReactNode;
  gridColumn?: string;
  gap?: string;
}

export const InputSet = ({
  direction = "row",
  children,
  gridColumn,
  gap = "12px",
}: InputSetProps) => {
  return (
    <S.InputSet $direction={direction} $gridColumn={gridColumn} $gap={gap}>
      {children}
    </S.InputSet>
  );
};
