import * as S from "./LoginForm.style";

interface LoginFormProps {
  children?: React.ReactNode;
  formButton?: React.ReactNode;
  onSubmit?: () => void;
}

export const LoginForm = ({
  children,
  formButton,
  onSubmit,
}: LoginFormProps) => {
  return (
    <S.LoginForm onSubmit={onSubmit}>
      <S.LoginInputBox>
        <S.LoginFormTit>로그인 입력</S.LoginFormTit>
        {children}
      </S.LoginInputBox>
      <S.LoginBtnBox>{formButton}</S.LoginBtnBox>
    </S.LoginForm>
  );
};
