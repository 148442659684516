import styled from "styled-components";

export const SubjectList = styled.ul`
  display: grid;
  height: calc(100vh - 340px);
  padding: 20px;
  grid-template-columns: repeat(3, 160px);
  grid-template-rows: repeat(2, calc(50% - 6px));
  gap: 24px 16px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: 100%;
    height: calc(100vh - 116px);
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    justify-content: center;
  }
`;
