import useAuthToken from "@/lib/hooks/useAuthToken";
import { Popover, PopoverProps } from "..";
import * as S from "./HeaderPopover.style";
import { ReactComponent as IconLogout } from "@/styles/assets/svg/icon_logout.svg";

interface HeaderPopoverProps extends PopoverProps {}

export const HeaderPopover = ({ setPopover }: HeaderPopoverProps) => {
  const { customLogout } = useAuthToken();
  return (
    <Popover setPopover={setPopover}>
      <S.HeaderPopover>
        <S.PopoverList>
          <S.PopoverItem onClick={customLogout}>
            <IconLogout />
            로그아웃
          </S.PopoverItem>
        </S.PopoverList>
      </S.HeaderPopover>
    </Popover>
  );
};
