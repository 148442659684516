import * as S from "./IntroTemplate.style";
import { ReactComponent as IconLogo } from "@/styles/assets/svg/logo_only.svg";

interface IntroTemplateProps {
  children?: React.ReactNode;
}

export const IntroTemplate = ({ children }: IntroTemplateProps) => {
  return (
    <S.IntroTemplate>
      <>
        <S.IntroLogo>
          <span>우리아이 성장발달 플래너</span>
          <IconLogo height={60} />
        </S.IntroLogo>
        <S.IntroTop>
          <S.IntroTopTit>WELCOME!</S.IntroTopTit>
          <S.IntroTopText>
            GGP는 다양한 학습놀이와 테스트를 통해
            <br />
            아이의 발달 체크와 성장에 도움을 드립니다.
          </S.IntroTopText>
        </S.IntroTop>

        <S.IntroBottom>{children}</S.IntroBottom>
      </>
    </S.IntroTemplate>
  );
};
