import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/atoms/Buttons";
import { LangPopover } from "@/components/molecules/Popover/LangPopover";
import * as S from "./LoginTemplate.style";
import { ReactComponent as IconBack } from "@/styles/assets/svg/icon_back.svg";
import { ReactComponent as IconLogo } from "@/styles/assets/svg/logo_only.svg";
import { ReactComponent as ImgLogin } from "@/styles/assets/svg/img_login.svg";
import { navigatorPushScreen } from "@/lib/flutterFuntions";
interface LoginTemplateProps {
  children?: any;
}

export const LoginTemplate = ({ children }: LoginTemplateProps) => {
  const navigate = useNavigate();
  const [formOpen, setFormOpen] = useState(false);

  return (
    <S.LoginTemplate $formOpen={formOpen}>
      <S.LoginLeft $formOpen={formOpen}>
        {formOpen && (
          <S.LoginLeftTop>
            <Button
              type="button"
              width="50px"
              color="gray100"
              layout="icon"
              size="sm"
              icon={<IconBack />}
              onClick={() => setFormOpen(false)}
            />
          </S.LoginLeftTop>
        )}

        <S.LoginLogo>
          <div>우리아이 성장발달 플래너</div>
          <IconLogo width={250} height={90} />
          <span>Good Growth Planner</span>
        </S.LoginLogo>
        {formOpen === false && (
          <S.LoginLeftContent>
            <Button
              type="button"
              width="50vw"
              size="md"
              layout="solid"
              color="red100"
              label="Log In"
              onClick={() => setFormOpen(true)}
            />
            <S.JoinBtnBox>
              아직 계정이 없으신가요?
              <Button
                type="button"
                size="xsm"
                layout="text"
                color="white100"
                label="회원가입 하기"
                onClick={() => navigate("/auth/join/form")}
              />
            </S.JoinBtnBox>
          </S.LoginLeftContent>
        )}
        {formOpen === true && <ImgLogin width={300} height={240} />}
      </S.LoginLeft>

      <S.LoginRight $formOpen={formOpen}>
        <S.LoginRightTop>{/* <LangPopover /> */}</S.LoginRightTop>
        {children}
      </S.LoginRight>
    </S.LoginTemplate>
  );
};
