import styled from "styled-components";

interface InputsStyleProps {
  $direction: "row" | "column";
}

export const InputBox = styled.div<InputsStyleProps>`
  position: relative;
  display: flex;
  flex-direction: ${(props) => props.$direction};
  gap: 4px;

  &::before {
    position: absolute;
    content: "";
    width: 0px;
    bottom: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.black50};
    transition: all 0.2s ease;
  }

  &:has(input[type="text"]:focus),
  &:has(input[type="password"]:focus),
  &:has(input[type="date"]:focus) {
    &::before {
      width: 100%;
    }
  }

  &:has(input[type="text"]:not(:placeholder-shown)),
  &:has(input[type="password"]:not(:placeholder-shown)) {
    &::before {
      width: 100%;
    }
  }

  input {
    &[type="text"],
    &[type="password"],
    &[type="date"] {
      width: 100%;
      height: 48px;
      padding: 0 8px;
      font-size: 1.6rem;
      border: none;
      border-radius: 6px 6px 0 0;
      outline: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.black12};

      &::placeholder {
        color: ${(props) => props.theme.colors.black38};
        font-size: 1.4rem;
        font-weight: 300;
      }
    }
  }
`;

export const InputLabel = styled.label<InputsStyleProps>`
  font-size: 1.3rem;

  span {
    color: ${(props) => props.theme.colors.destructive100};
  }
`;
