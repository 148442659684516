import { ButtonGrowLearn } from "@/components/atoms/Buttons/ButtonGrowLearn";
import { LinkList } from "@/components/molecules/LinkList";
import { MyInfoBox } from "@/components/molecules/MyInfoBox";
import { SubLinkList } from "@/components/molecules/SubLinkList";
import { MyProfile } from "@/components/organisms/MyProfile";
import { MainTemplate } from "@/components/templates/MainTemplate";

import { ReactComponent as IconSNS01 } from "@/styles/assets/svg/icon_monitor.svg";
import { ReactComponent as IconSNS02 } from "@/styles/assets/svg/icon_kakao.svg";
import { ReactComponent as IconSNS03 } from "@/styles/assets/svg/icon_instagram.svg";
import { ReactComponent as IconSNS04 } from "@/styles/assets/svg/icon_youtube.svg";

import { ReactComponent as IconAsk01 } from "@/styles/assets/svg/icon_call.svg";
import { ReactComponent as IconAsk02 } from "@/styles/assets/svg/icon_email.svg";

export const MyPage = () => {
  const sns = [
    {
      id: 1,
      menuTitle: "앙파파 자사몰",
      icon: <IconSNS01 width={18} height={18} />,
      path: "/home",
    },
    {
      id: 2,
      menuTitle: "그로우러닝 카카오톡 문의",
      icon: <IconSNS02 width={18} height={18} />,
      path: "/learn",
    },
    {
      id: 3,
      menuTitle: "그로우러닝 공식 인스타그램",
      icon: <IconSNS03 width={18} height={18} />,
      path: "/exam",
    },
    {
      id: 4,
      menuTitle: "그로우러닝 공식 유튜브 채널",
      icon: <IconSNS04 width={18} height={18} />,
      path: "/community",
    },
  ];

  const ask = [
    {
      id: 1,
      menuTitle: "고객센터 전화문의",
      icon: <IconAsk01 width={18} height={18} />,
      path: "/home",
    },
    {
      id: 2,
      menuTitle: "이메일 문의",
      icon: <IconAsk02 width={18} height={18} />,
      path: "/learn",
    },
  ];

  return (
    <MainTemplate>
      <MyProfile>
        {/* 프로필 */}
        <MyInfoBox />

        {/* 그로우러닝 맞춤 분석 */}
        <ButtonGrowLearn />
      </MyProfile>

      {/* 메뉴 */}
      <LinkList title="앙파파&그로우러닝 SNS" data={sns} />
      <LinkList title="문의" data={ask} />

      {/* 부가메뉴 */}
      <SubLinkList />
    </MainTemplate>
  );
};
