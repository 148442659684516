import styled from "styled-components";

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ContentTitBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ContentTit = styled.h3`
  font-size: 1.8rem;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    font-size: 1.7rem;
  }
`;

export const Content = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const ContentList = styled.ul`
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  width: 100%;
  padding: 6px 2px;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
`;
