import { useNavigate } from "react-router-dom";

import { Button } from "@/components/atoms/Buttons";
import { InputText } from "@/components/atoms/Inputs/InputText/indext";
import { InputRadio } from "@/components/atoms/Inputs/InputRadio";
import { Form } from "@/components/molecules/Form";
import { InputSet } from "@/components/molecules/InputSet";
import { JoinForm } from "@/components/organisms/JoinForm";
import { JoinTemplate } from "@/components/templates/JoinTemplate";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { request } from "@/lib/api";
import { ResponseType } from "@/types/response.types";
import { ApiError } from "@/types/apiError";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";

export const schema = yup
  .object({
    purchase_location: yup.string().required("구입처를 선택해주세요."),
    order_name: yup.string().required("주문자명을 입력해주세요"),
    birth_date: yup.string().required("생년월일을 입력해주세요"),
    phone: yup.string().required("연락처를 입력해주세요"),
    gender: yup.string().required("성별을 선택해주세요"),
    order_number: yup.string().required("주문번호를 입력해주세요"),
  })
  .required();

export const JoinBuyPage = () => {
  /** ============================= state 영역 ============================= */
  const navigate = useNavigate();

  /** 구매인증 메인 useForm  */
  const CertificationForm = useForm<any>({
    resolver: yupResolver(schema),
  });
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = CertificationForm;
  /** ============================= API 영역 ============================= */

  console.log("errors", errors);
  /** 구매인증 API 호출 */
  const { mutateAsync: onPurchaseVerification } = useMutation(
    ["ONPURCHASEVERIFICATION"],
    (purchaseVerificationData: any) =>
      request<ResponseType<any>>({
        method: "POST",
        url: `/auth/purchase-verification`, // 슬래시(/)를 앞에 추가하여 절대 경로로 변경
        data: purchaseVerificationData,
      }).catch((error: any) => {
        throw new ApiError({
          message: error.message,
        });
      }),
    {
      onSuccess(data: ResponseType<any>) {
        if (data.success) {
          toast.success(data.message);
          navigate("/auth/profile/select");
        } else {
          toast.error(data.message);
        }
      },
      onError(error: any) {
        toast.error(error.message);
      },
    }
  );

  /** ============================= 비즈니스 로직 영역 ============================= */

  /** 회원가입 함수  */
  const onSubmit = async (data: any) => {
    console.log("data", data);
    try {
      await onPurchaseVerification({ ...data }); // 구매인증 api 호출
    } catch (error: any) {
      console.log("error", error);
    }
  };

  /** 나중에 인증하기 함수 */
  const handleLater = async (data: any) => {
    try {
      await onPurchaseVerification({ type: "later" }); // 구매인증 api 호출
    } catch (error: any) {
      console.log("error", error);
    }
  };

  /** ============================= 컴포넌트 영역 ============================= */

  /** ============================= useEffect 영역 ============================= */
  /** useForm errors 객체에서 가장 첫부분의 에러를 띄우는 useEffect */
  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      const firstKey = keys[0];
      const firstMessage = errors[firstKey]?.message;

      if (firstMessage) {
        toast.error(`${firstMessage}`);
      } else {
        console.log("message 속성이 존재하지 않습니다.");
      }
    } else {
      console.log("객체에 키가 없습니다.");
    }
  }, [errors]);

  return (
    <JoinTemplate>
      <JoinForm
        title="그로우러닝 구매 인증하기"
        onSubmit={handleSubmit(onSubmit)}
        text={
          <>
            구매 인증은 아이디 당 한번만 가능하며, 양도가 불가능합니다.
            <br />앱 다운로드 7일 내에 구매인증을 하지 않으면 앱 사용이 제한될
            수 있습니다.
          </>
        }
        formButton={
          <Button
            type="submit"
            width="100vw"
            size="full"
            color="primary100"
            layout="solid"
            label="구매이력 제출"
          />
        }
      >
        {/* 회원가입 폼 */}
        <Form>
          <InputRadio
            label="구입처"
            name="purchase_location"
            data={[
              { value: "self", displayExpr: "자사몰" },
              { value: "smart_store", displayExpr: "스마트스토어" },
              { value: "open_market", displayExpr: "쿠팡 등 오픈마켓" },
            ]}
            onChange={CertificationForm?.setValue}
            inputForm={CertificationForm && CertificationForm}
            required={true}
          />
          <InputSet gridColumn="repeat(2, 1fr)" gap="30px">
            <InputText
              label="주문자명"
              placeholder="주문자명을 입력해 주세요."
              register={register("order_name")}
              required={true}
            />
            <InputText
              label="생년월일"
              placeholder="생년월일 8자리를 입력해 주세요 (예 : 19900101)"
              register={register("birth_date")}
              required={true}
              maxLength={8}
            />
          </InputSet>
          <InputSet gridColumn="repeat(2, 1fr)" gap="30px">
            <InputText
              label="연락처"
              placeholder="연락처를 입력해 주세요."
              register={register("phone")}
              required={true}
              maxLength={11}
            />
            <InputRadio
              label="성별"
              name="gender"
              data={[
                { value: "male", displayExpr: "남" },
                { value: "female", displayExpr: "여" },
              ]}
              onChange={CertificationForm?.setValue}
              inputForm={CertificationForm && CertificationForm}
              required={true}
            />
          </InputSet>
          <InputText
            label="주문번호"
            placeholder="주문번호를 입력해 주세요."
            register={register("order_number")}
            required={true}
          />
          <div>
            <div
              style={{
                color: "#999999",
                fontSize: "14px",
                fontWeight: "400",
                display: "flex",
                flexDirection: "row-reverse",
                cursor: "pointer",
              }}
              onClick={handleLater}
            >
              나중에 인증하기 {">"}
            </div>
          </div>
        </Form>
      </JoinForm>
    </JoinTemplate>
  );
};
