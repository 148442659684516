import styled from "styled-components";

export const MyPageForm = styled.div`
  display: flex;
  align-items: enter;
  flex-direction: column;
  gap: 30px;
`;

export const FormTitBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

export const FormTit = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
`;

export const FormTxt = styled.p`
  color: ${(props) => props.theme.colors.black38};
  font-size: 1.2rem;
  text-align: center;
  line-height: 160%;
`;
