import Slider from "react-slick";
import { CarouselItem } from "@/components/atoms/CarouselItem";
import * as S from "./IntroCarousel.style";
import Intro01 from "@/styles/assets/img/intro01.png";
import Intro02 from "@/styles/assets/img/intro02.png";
import Intro03 from "@/styles/assets/img/intro03.png";
import Intro04 from "@/styles/assets/img/intro04.png";
import Intro05 from "@/styles/assets/img/intro05.png";

export const IntroCarousel = () => {
  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerPadding: "20px",
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [{
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },{
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },{
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }]
  };

  return (
    <S.IntroCarousel className="slider-container">
      <Slider {...settings}>
        <CarouselItem>
          <img src={Intro01} alt="인트로 이미지" />
        </CarouselItem>
        <CarouselItem>
          <img src={Intro02} alt="인트로 이미지" />
        </CarouselItem>
        <CarouselItem>
          <img src={Intro03} alt="인트로 이미지" />
        </CarouselItem>
        <CarouselItem>
          <img src={Intro04} alt="인트로 이미지" />
        </CarouselItem>
        <CarouselItem>
          <img src={Intro05} alt="인트로 이미지" />
        </CarouselItem>
      </Slider>
    </S.IntroCarousel>
  );
};
