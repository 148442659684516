import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./NavItem.style";
import { navigatorRoutePushScreen } from "@/lib/flutterFuntions";

interface NavItemProps {
  data?: any;
}

export const NavItem = ({ data }: NavItemProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <S.NavItem>
      <S.NavLink
        // href="/home"
        onClick={
          data.isUse
            ? () => {
                if (data.app) {
                  navigatorRoutePushScreen("/", "", () => {});
                } else {
                  navigate(data.path);
                }
              }
            : undefined
        }
        $active={pathname.includes(data.path) ? true : false}
      >
        {data.icon}
        {data.menuTitle}
      </S.NavLink>
    </S.NavItem>
  );
};
