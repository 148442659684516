import styled from "styled-components";

export const InputCheckbox = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  color: ${(props) => props.theme.colors.white100};
  font-size: 1.6rem;
  align-items: center;
  gap: 8px;
`;

export const Checkbox = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  border: 1px solid ${(props) => props.theme.colors.white100};
  border-radius: 2px;
  align-items: center;
  justify-content: center;

  input {
    display: none;
  }

  svg {
    display: none;

    path {
      fill: ${(props) => props.theme.colors.white100};
    }
  }

  &:has(input:checked) {
    border: none;

    svg {
      display: initial;
    }
  }
`;
