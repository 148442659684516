import styled, { css } from "styled-components";
import { ModalStyleProps } from ".";

export const ModalBG = styled.div`
  position: fixed;
  display: -webkit-box;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 999;
`;

export const ModalBox = styled.div<ModalStyleProps>`
  display: -webkit-box;
  display: flex;
  width: calc(100vw);
  max-width: 500px;
  max-height: 100vh;
  padding: ${(props) => (props.padding ? props.padding + "px" : "30px")};
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-orient: vertical;
  flex-direction: column;
  gap: 20px;

  //IOS, safari 14 이하 버전 gap 적용
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  ${(props) =>
    props.size === "md" &&
    css`
      width: 300px;
    `}

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: calc(100vw - 32px);
  }
`;

export const ModalHeader = styled.div`
  display: -webkit-box;
  display: flex;
  width: 100%;
  color: #000000;
  font-size: 2rem;
  font-weight: 500;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const ModalBody = styled.div`
  overflow: auto;
  display: -webkit-box;
  display: flex;
  padding: 5px;
  color: #666666;
  font-size: 1.4rem;
  line-height: 1.8rem;
  -webkit-box-orient: vertical;
  flex-direction: column;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
