import { useRecoilState } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { request } from "../api";
import { userInfoSelector } from "@/recoil/userInfo";
import { DEVICE_TYPE } from "@/data/dummy";

export default function useAuthToken() {
  const [userInfo, setUserInfo] = useRecoilState(userInfoSelector);
  const navigate = useNavigate();
  const location = useLocation();

  const customLogin = async (userInfo: any) => {
    try {
      setUserInfo(userInfo);
      let from;
      // 이전 페이지가 있는 경우 해당 페이지로 이동
      if (location.state?.from) {
        from = location.state.from;
      } else {
        // 첫 로그인 여부에 따라 분기 후, 기기별(폰, 패드) 페이지 이동
        if (userInfo.is_first_login) {
          // 첫 로그인이라면 관심 주제 설정 페이지로 이동
          from = "/auth/join/select";
        } else {
          if (DEVICE_TYPE === "phone") {
            // 이전 페이지가 없다면 기본 세팅
            from = "/home";
          } else {
            from = "/auth/profile/select";
          }
        }
      }

      navigate(from, { replace: true });
    } catch (e: any) {
      console.error("로그인 실패:", e.message);
    }
  };

  const customLogout = async () => {
    try {
      await request({ method: "POST", url: "/auth/logout" });
      setUserInfo(null);
      localStorage.removeItem("selectProfileId");
      navigate("/auth/login", { replace: true });
    } catch (error) {
      console.error("로그아웃 실패:", error);
    }
  };

  const fetchAdminInfo = async () => {
    try {
      const response: any = await request({
        method: "GET",
        url: "/auth/info",
      });

      setUserInfo(response.data);
      return true;
    } catch (error) {
      setUserInfo(null);
      throw error; // AuthGuard에서 처리하도록 에러를 전파
    }
  };

  return { customLogin, customLogout, fetchAdminInfo, userInfo };
}
