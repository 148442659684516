import { GrowProfileList } from "@/components/molecules/GrowProfileList";
import { MyPageForm } from "@/components/organisms/MyPageForm";
import { MainTemplate } from "@/components/templates/MainTemplate";
import Img from "@/styles/assets/img/img_profile.png";

export const MyProfileSelectPage = () => {
  const dummy = [
    {
      id: 1,
      name: "김채원",
      age: "34m 4d",
      gender: "여",
      text: "나는야 대근육 왕 탐험가",
      img: Img,
    },
    {
      id: 2,
      name: "김소희",
      age: "52m 16d",
      gender: "여",
      text: "섬세한 감수성의 예술가",
      img: "",
    },
    {
      id: 3,
      name: "정이수",
      age: "만 36세",
      gender: "여",
      text: "긍정의 힘 평화주의자",
      img: "",
    },
  ];

  return (
    <MainTemplate>
      <MyPageForm title="그로우러닝 맞춤 분석" text="프로필을 선택해 주세요">
        <GrowProfileList data={dummy} />
      </MyPageForm>
    </MainTemplate>
  );
};
