import { Inputs, InputsProps } from "..";

interface InputPasswordProps extends InputsProps {
  label?: string;
  required?: boolean;
}

export const InputPassword = ({
  label,
  placeholder,
  direction,
  register,
  required,
}: InputPasswordProps) => {
  return (
    <Inputs label={label && label} direction={direction} required={required}>
      <input type="password" placeholder={placeholder} {...register} />
    </Inputs>
  );
};
