import styled, { css } from "styled-components";

interface ProfileCardItemStyleProps {
  $data?: boolean;
  $pinned?: boolean;
}

export const ProfileCardItem = styled.li<ProfileCardItemStyleProps>`
  display: flex;
  width: 240px;
  height: 320px;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.shadows.item};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  transition: all 0.2s ease;

  ${(props) =>
    props.$data === true &&
    css`
      background: ${props.$pinned
        ? props.theme.colors.secondary100
        : props.theme.colors.white100};
    `}

  ${(props) =>
    props.$data === false &&
    css`
      background: ${props.theme.colors.black12};
    `}

    ${(props) =>
    props.$pinned === true &&
    css`
      margin-bottom: 80px;
      box-shadow: ${props.theme.shadows.profile};
    `}

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: 100%;
    height: 110px;
    padding: 0 20px;
    justify-content: flex-start;
    flex-direction: row;

    ${(props) =>
      props.$pinned === true &&
      css`
        margin-bottom: 0;
        box-shadow: ${props.theme.shadows.profile};
      `}
  }
`;

export const ProfileImg = styled.div<ProfileCardItemStyleProps>`
  position: relative;
  display: flex;
  width: 180px;
  height: 180px;
  background: ${(props) => props.theme.colors.white100};
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  & > svg {
    path {
      fill: ${(props) => props.theme.colors.black12};
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  ${(props) =>
    props.$data === false &&
    css`
      padding: 10px;
    `}

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: 80px;
    height: 80px;

    & > svg {
      width: 80px;
      height: 80px;
    }

    ${(props) =>
      props.$data === false &&
      css`
        padding: 5px;
      `}
  }
`;

export const ProfileAddIcon = styled.span`
  position: absolute;
  right: 0;
  bottom: -8px;

  & > svg {
    background: ${(props) => props.theme.colors.white100};
    border-radius: 50%;

    path {
      fill: ${(props) => props.theme.colors.secondary100};
    }
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    & > svg {
      width: 30px;
      height: 30px;
    }
  }
`;

export const ProfileInfo = styled.div<ProfileCardItemStyleProps>`
  display: flex;
  min-height: 60px;
  color: ${(props) =>
    props.$pinned === true
      ? props.theme.colors.white100
      : props.theme.colors.black100};
  font-size: 2.5rem;
  font-weight: ${(props) => (props.$pinned === true ? "800" : "700")};
  letter-spacing: 1.2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    font-size: 2rem;
    letter-spacing: 0.4rem;
    align-items: flex-start;
  }
`;

export const ProfileInfoDetail = styled.div<ProfileCardItemStyleProps>`
  display: flex;
  color: ${(props) =>
    props.$pinned === true
      ? props.theme.colors.white100
      : props.theme.colors.black38};
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
  align-items: center;
  gap: 16px;

  span {
    &:last-of-type {
      padding-left: 16px;
      border-left: 1px solid
        ${(props) =>
          props.$pinned === true
            ? props.theme.colors.white100
            : props.theme.colors.black38};
    }
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    font-size: 1.6rem;
  }
`;

export const ProfileNoData = styled.span`
  color: ${(props) => props.theme.colors.black30};
  letter-spacing: 0;
`;
