import { GlobalFonts } from "@/styles/assets/fonts/fonts";
import { GlobalStyle } from "@/styles/global-style";

interface GlobalStyleProviderProps {
  children: React.ReactNode;
}

export const GlobalStyleProvider = ({ children }: GlobalStyleProviderProps) => {
  return (
    <>
      <GlobalFonts />
      <GlobalStyle />
      {children}
    </>
  );
};
