import styled from "styled-components";

export const InputLogin = styled.div`
  position: relative;
  display: flex;
  padding-top: 18px;
  flex-direction: column;

  label {
    position: absolute;
    top: 36px;
    left: 10px;
    opacity: 0.8;
  }

  &::after {
    position: relative;
    content: "";
    display: block;
    width: 0;
    bottom: 2px;
    left: 0;
    border-bottom: 2px solid ${(props) => props.theme.colors.white100};
    transition: all 0.2s ease;
  }

  &:has(input:focus) {
    label {
      top: 0px;
      left: 0px;
      font-size: 1.2rem;
      opacity: 1;
    }

    input {
      background: ${(props) => props.theme.colors.white12};

      &::placeholder {
        opacity: 1;
      }
    }

    &::after {
      width: 28vw;
    }
  }

  &:has(input:not(:placeholder-shown)) {
    label {
      top: 0px;
      left: 0px;
      font-size: 1.2rem;
      opacity: 1;
    }

    input {
      background: ${(props) => props.theme.colors.white12};
    }

    &::after {
      width: 28vw;
    }
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    &:has(input:focus) {
      &::after {
        width: 100%;
      }
    }

    &:has(input:not(:placeholder-shown)) {
      &::after {
        width: 100%;
      }
    }
  }
`;

export const InputLabel = styled.label`
  color: ${(props) => props.theme.colors.white100};
  font-size: 1.4rem;
  font-weight: 600;
  transition: all 0.2s ease;
`;

export const Input = styled.input`
  position: relative;
  width: 28vw;
  height: 50px;
  padding: 0 10px;
  color: ${(props) => props.theme.colors.white100};
  font-size: 1.6rem;
  background: none;
  border: none;
  border-radius: 6px 6px 0 0;
  border-bottom: 2px solid ${(props) => props.theme.colors.white60};
  outline: none;
  transition: all 0.2s ease;

  &::placeholder {
    color: ${(props) => props.theme.colors.white70};
    font-size: 1.4rem;
    opacity: 0;
    transition: all 0.2s ease;
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: calc(100vw - 122px);
  }
`;
