import styled from "styled-components";

export const SubLinkItem = styled.li`
  position: relative;
  display: flex;
  height: 36px;
  color: ${(props) => props.theme.colors.black50};
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
`;
