import styled from "styled-components";

export const SubjectItem = styled.li`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.white100};
  border: none;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.shadows.item};
  transition: all 0.2s ease;

  svg {
    position: absolute;
    top: -17px;
    left: calc(50% - 17px);
    background: ${(props) => props.theme.colors.white100};
    border-radius: 50%;
    box-shadow: ${(props) => props.theme.shadows.banner};
    transition: all 0.2s ease;
    opacity: 0;

    path {
      fill: ${(props) => props.theme.colors.secondary100};
    }
  }

  &:has(input:checked) {
    border: 1px solid ${(props) => props.theme.colors.secondary100};

    svg {
      opacity: 1;
    }
  }
`;

export const SelectLabel = styled.label`
  display: grid;
  width: 100%;
  height: 100%;
  padding: 18px;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: calc(100% - 38px) 26px;
  gap: 12px;
  transition: all 0.2s ease;

  input {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &:has(input:checked) {
    box-shadow: none;

    span {
      color: ${(props) => props.theme.colors.secondary100};
      font-weight: 700;
    }
  }
`;

export const SelectTxt = styled.span`
  color: ${(props) => props.theme.colors.black100};
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
`;
