import { useRecoilState } from "recoil";
import { Routes as DomReroutes, Navigate, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
// 디바이스 타입 확인
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import { DEVICE_TYPE } from "@/data/dummy";

// 인트로 페이지
import { IntroPage } from "@/pages/IntroPage";

// 메인 홈
import { MainPage } from "@/pages/MainPage";

// 로그인
import { LoginPage } from "@/pages/auth/LoginPage";

// 회원가입
import { JoinFormPage } from "@/pages/auth/join/JoinFormPage";
import { JoinSelectPage } from "@/pages/auth/join/JoinSelectPage";
import { JoinBuyPage } from "@/pages/auth/join/JoinBuyPage";

// 프로필 설정
import { ProfileSelectPage } from "@/pages/auth/profile/ProfileSelectPage";
import { ProfileFormPage } from "@/pages/auth/profile/ProfileFormPage";

import { userInfoSelector } from "@/recoil/userInfo";
import { useRecoilValue } from "recoil";
// 마이페이지
import { MyPage } from "@/pages/mypage/MyPage";
import { MyProfileSelectPage } from "@/pages/mypage/MyProfileSelectPage";

interface RouterItem {
  path: string;
  element: React.ReactNode;
  withAuthorization: boolean;
  pageTitle?: string;
}

const routerItems: RouterItem[] = [
  /* 인트로 */
  {
    path: "/intro",
    element: <IntroPage />,
    withAuthorization: false,
  },

  /* 메인 홈 */
  {
    path: "/home",
    element: <MainPage />,
    withAuthorization: false,
  },

  /* 로그인 */
  {
    path: "/auth/login",
    element: <LoginPage />,
    withAuthorization: false,
  },

  /* 회원가입 */
  {
    path: "/auth/join/form",
    element: <JoinFormPage />,
    withAuthorization: false,
  },
  {
    path: "/auth/join/select",
    element: <JoinSelectPage />,
    withAuthorization: false,
  },
  {
    path: "/auth/join/buy",
    element: <JoinBuyPage />,
    withAuthorization: false,
  },

  /* 프로필 설정 */
  {
    path: "/auth/profile/select",
    element: <ProfileSelectPage />,
    withAuthorization: false,
  },
  {
    path: "/auth/profile/form",
    element: <ProfileFormPage />,
    withAuthorization: false,
  },

  /* 마이페이지 */
  {
    path: "/mypage",
    element: <MyPage />,
    withAuthorization: false,
  },
  {
    path: "/mypage/select",
    element: <MyProfileSelectPage />,
    withAuthorization: false,
  },
];

export function Routes() {
  const userInfo = useRecoilValue(userInfoSelector);

  return (
    <DomReroutes>
      <Route
        path="/"
        element={
          <PrivateRoute
            component={
              userInfo?.is_first_login ? (
                <Navigate to="/auth/join/select" replace />
              ) : (
                <Navigate to="/auth/profile/select" replace />
              )
            }
          />
        }
      />

      {routerItems
        .filter((route) => !route.withAuthorization)
        .map((route: RouterItem) => {
          return (
            <Route key={route.path} path={route.path} element={route.element} />
          );
        })}
    </DomReroutes>
  );
}
