import styled, { css } from "styled-components";

interface InputButtonSetStyleProps {
  $direction: "row" | "column";
  $gridColumn?: string;
  $gap: string;
}

export const InputButtonSet = styled.div<InputButtonSetStyleProps>`
  display: ${(props) => (props.$gridColumn ? "grid" : "flex")};
  width: 100%;
  flex-direction: ${(props) => props.$direction};
  align-items: end;
  gap: ${(props) => props.$gap};

  ${(props) =>
    props.$gridColumn &&
    css`
      grid-template-columns: ${props.$gridColumn};
    `}
`;
