import { ApiError } from "@/types/apiError";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";

// console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

api.interceptors.request.use(
  async (config: any) => {
    const ip = localStorage.getItem("userIP");
    if (ip) config.headers.ip = ip;
    return config;
  },
  (error: any) => Promise.reject(error)
);
api.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/refresh-token`,
          {},
          {
            withCredentials: true,
          }
        );

        // 토큰이 재발급되었으므로, 원래의 요청을 다시 보냅니다.
        return api(originalRequest);
      } catch (e) {
        // 리프레시 토큰이 만료되었거나 유효하지 않은 경우 로그아웃 처리
        // RecoilLogout();
        return Promise.reject(e);
      }
    }

    return Promise.reject(error);
  }
);

const request = async <T>(
  config: AxiosRequestConfig,
  isShowError: boolean = true
): Promise<T> => {
  const { method } = config;
  const isGetRequest = method?.toUpperCase() === "GET";

  try {
    const { data } = await api.request<T>({ ...config });

    if (!isGetRequest && (data as any)?.success === false) {
      if (isShowError) {
        toast.error((data as any)?.message ?? "서버요청 에러!");
      }
      throw new ApiError({
        message: (data as any)?.message ?? "서버요청 에러!",
      });
    }

    return data;
  } catch (error: any) {
    let message = "서버요청 에러!";

    if (error instanceof AxiosError) {
      const { response }: any = error;
      message = response?.data?.message ?? message;

      if (isGetRequest) {
        if (error?.response?.status === 403) {
          toast.error("조회 권한이 없습니다");
        }
        return [] as any;
      }
    } else if (error instanceof ApiError) {
      message = error.message;
    }

    if (isShowError && !isGetRequest) {
      // toast.error(message);
    }

    throw error;
  }
};

const setUserId = (userId: any) => {
  api.defaults.headers.common["userId"] = userId;
};

const setToken = (token: any) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const setIp = (ip: string) => {
  api.defaults.headers.common["ip"] = ip;
};

export { request, setUserId, setToken, setIp };
