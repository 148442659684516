import React from "react";
import * as S from "../modal.styles";
import { DaumPostcodeEmbed } from "react-daum-postcode";
import { Button } from "@/components/atoms/Buttons";

interface PostCodeModalProps {
  title?: string;
  onClose?: any;
  complete: (data: object) => void;
}
const PostCodeModal = ({ title, onClose, complete }: PostCodeModalProps) => {
  return (
    <S.ModalBG onClick={onClose}>
      <S.ModalBox>
        <S.ModalHeader>{title}</S.ModalHeader>
        <DaumPostcodeEmbed
          autoClose
          onComplete={complete}
          style={{
            width: "100%",
          }}
        />
        <S.ModalFooter>
          <Button
            type="button"
            width="600px"
            size="input"
            color="secondary100"
            layout="outline"
            label="닫기"
            onClick={onClose}
          />
        </S.ModalFooter>
      </S.ModalBox>
    </S.ModalBG>
  );
};

export default PostCodeModal;
