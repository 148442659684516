import * as S from "./MyInfoBox.style";

import { ReactComponent as IconUser } from "@/styles/assets/svg/icon_user.svg";
import { ReactComponent as IconPlus } from "@/styles/assets/svg/icon_plus.svg";

export const MyInfoBox = () => {
  return (
    <S.MyInfoBox>
      <S.MyInfo>
        {/* 프로필 사진 설정 */}
        <S.MyProfileImg>
          <input
            type="file"
            id="profileImage"
            accept="image/*"
            // onChange={onChange}
          />
          {/* {imgUrl?.path ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/${imgUrl?.path}`}
              alt="profile"
              width={60}
              height={60}
              style={{
                borderRadius: "50%",
              }}
            />
          ) : ( */}
          <IconUser width={60} height={60} />
          {/* )} */}

          <span>
            <IconPlus />
          </span>
        </S.MyProfileImg>

        {/* 계정회원 정보 */}
        <S.MyUser>
          <S.UserTypeChip>VIP</S.UserTypeChip>
          이수빈 님
        </S.MyUser>
      </S.MyInfo>

      <S.UserBenefitBox>
        <S.Benefit>
          <span>포인트</span>
          5,000 P
        </S.Benefit>
        <S.Benefit>
          <span>내 쿠폰함</span>3 개
        </S.Benefit>
      </S.UserBenefitBox>
    </S.MyInfoBox>
  );
};
