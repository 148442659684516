import styled from "styled-components";

interface ProfileListStyleProps {}

export const ProfileList = styled.ul`
  display: flex;
  height: calc(100vh - 340px);
  align-items: center;
  gap: 16px;
  overflow: auto;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
`;
