import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/atoms/Buttons";
import { ProfileList } from "@/components/molecules/ProfileList";
import { JoinForm } from "@/components/organisms/JoinForm";
import { JoinTemplate } from "@/components/templates/JoinTemplate";

import ImgProfile from "@/styles/assets/img/img_profile.png";
import { request } from "@/lib/api";
import { useQuery } from "@tanstack/react-query";
import { ResponseType } from "@/types/response.types";
import { toast } from "react-toastify";

export const ProfileSelectPage = () => {
  /** ============================= state 영역 ============================= */
  const navigate = useNavigate();
  const [profile, setProfile] = useState<string>();

  /** ===============/** 오더 그리드 리스트  */
  const { data: profileList, isLoading: orderListLoading } = useQuery(
    ["USER_CHILD_PROFILE_LIST"],
    async () => {
      const result = await request<ResponseType<any>>({
        method: "GET",
        url: `profile`,
      });

      return result.data;
    },
    {
      onError: (error) => {
        console.error("Query Error:", error); // 에러 처리 로직
        // 추가적인 에러 처리 로직
      },
    }
  );

  /** ============================= 비즈니스 로직 영역 ============================= */
  const handleProfile = (profileId: any) => {
    localStorage.setItem("selectProfileId", profileId);
    return setProfile(profileId);
  };

  /** 입장하기 버튼 핸들러 */
  const handleEnter = () => {
    const selectProfileId = localStorage.getItem("selectProfileId");

    // selectProfileId가 있으면 입장하기 버튼 클릭 시 홈으로 이동
    // !!  selectProfileId가 유효한 아디인지 db에서 확인하는 로직 추가 필요
    if (selectProfileId) {
      navigate("/");
    } else {
      toast.error("프로필을 선택해 주세요.");
    }
  };

  console.log(profileList);
  console.log(profile);
  /** ============================= 컴포넌트 영역 ============================= */

  /** ============================= useEffect 영역 ============================= */

  return (
    <JoinTemplate>
      <JoinForm
        title="PROFILE"
        text="프로필을 생성 및 선택 후 입장해 주세요."
        formButton={
          <Button
            type="button"
            width="100vw"
            size="full"
            color="primary100"
            layout="solid"
            label="입장하기"
            onClick={handleEnter}
          />
        }
      >
        <ProfileList
          data={profileList}
          // data={dummy}
          onClick={(e: any) => handleProfile(e)}
          selectedProfile={profile}
        />
      </JoinForm>
    </JoinTemplate>
  );
};
