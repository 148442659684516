import { Button } from "../Buttons";
import * as S from "./GrowProfileItem.style";

import { ReactComponent as IconUser } from "@/styles/assets/svg/icon_user.svg";
import { ReactComponent as IconPlus } from "@/styles/assets/svg/icon_plus.svg";

interface GrowProfileItemProps {
  data?: any;
}

export const GrowProfileItem = ({ data }: GrowProfileItemProps) => {
  return (
    <S.GrowProfileItem>
      <S.UserBox>
        <S.UserInfoBox>
          <span>{data.text}</span>
          <S.UserInfo>
            <S.UserName>{data.name}</S.UserName>
            {data.age} / {data.gender}
          </S.UserInfo>
        </S.UserInfoBox>

        <Button
          type="button"
          size="sm"
          layout="solid"
          color="primary100"
          label="맞춤 분석 보기"
        />
      </S.UserBox>

      <S.UserImg>
        {data.img ? (
          <img src={data.img} alt="프로필 이미지" width={100} height={100} />
        ) : (
          <S.UserImgInput>
            <input type="file" />
            <IconUser width={100} height={100} />
            <span>
              <IconPlus width={38} height={38} />
            </span>
          </S.UserImgInput>
        )}
      </S.UserImg>
    </S.GrowProfileItem>
  );
};
