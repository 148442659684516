import styled from "styled-components";

interface LoginTemplateProps {
  $formOpen?: boolean;
}

export const LoginTemplate = styled.div<LoginTemplateProps>`
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: ${(props) =>
    props.$formOpen === false ? "100vw 0" : "50vw 50vw"};
  transition: all 0.2s ease;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    grid-template-columns: ${(props) =>
      props.$formOpen === false ? "100vw 0" : "0 100vw"};
  }
`;

export const LoginLeft = styled.section<LoginTemplateProps>`
  display: flex;
  padding: ${(props) =>
    props.$formOpen === false ? "16vw 0 60px" : "120px 0 60px"};
  background: ${(props) => props.theme.colors.primary100};
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  transition: all 0.2s ease;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    padding: 20vh 0 40px;
  }
`;

export const LoginLeftTop = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;

  svg {
    path {
      fill: ${(props) => props.theme.colors.white100};
    }
  }
`;

export const LoginLogo = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.white100};
  align-items: center;
  flex-direction: column;
  gap: 20px;

  div {
    width: 250px;
    font-size: 1.6rem;
  }

  & > span {
    margin-top: 4px;
    font-size: 2rem;
    font-weight: 800;
    letter-spacing: 0.08rem;
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    gap: 12px;

    svg {
      width: 200px;
      height: 80px;
    }

    div {
      width: 200px;
      font-size: 1.4rem;
    }

    & > span {
      font-size: 1.6rem;
    }
  }
`;

export const LoginLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    gap: 40px;

    & > button {
      width: 80vw !important;
    }
  }
`;

export const JoinBtnBox = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.white100};
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
  gap: 12px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const LoginRight = styled.section<LoginTemplateProps>`
  overflow: hidden;
  display: flex;
  padding: 60px 0;
  background: ${(props) => props.theme.colors.secondary100};
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    padding: ${(props) => (props.$formOpen === true ? "40px 0" : "0")};
  }
`;

export const LoginRightTop = styled.div``;
