import * as S from "./LinkItem.style";

interface LinkItemProps {
  data?: any;
}

export const LinkItem = ({ data }: LinkItemProps) => {
  return (
    <S.LinkItemBox>
      {data.icon}
      <S.LinkTit>{data.menuTitle}</S.LinkTit>
    </S.LinkItemBox>
  );
};
