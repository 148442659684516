import styled from "styled-components";

export const LinkItemBox = styled.li`
  display: flex;
  height: 46px;
  padding: 0 16px;
  align-items: center;
  gap: 12px;
`;

export const LinkTit = styled.div`
  font-size: 1.3rem;
`;
