import styled from "styled-components";

export const LoginForm = styled.form`
  display: flex;
  width: 28vw;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: calc(100vw - 122px);
  }
`;

export const LoginFormTit = styled.h3`
  width: 100%;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.white100};
  font-size: 2.8rem;
  font-weight: 600;
`;

export const LoginInputBox = styled.div`
  display: flex;
  width: 28vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: calc(100vw - 122px);
  }
`;

export const LoginBtnBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    & > button {
      width: calc(100vw - 122px);
    }
  }
`;
