import { SubLinkItem } from "@/components/atoms/SubLinkItem";
import * as S from "./SubLinkList.style";

export const SubLinkList = () => {
  return (
    <S.SubLinkList>
      <SubLinkItem title="고객센터" />
      <SubLinkItem title="1:1문의" />
      <SubLinkItem title="앱 설정" />
    </S.SubLinkList>
  );
};
