import { ContentPostItem } from "@/components/atoms/ContentPostItem";
import { ImgBanner } from "@/components/atoms/ImgBanner";
import { TestItem } from "@/components/atoms/TestItem";
import { ContentBox } from "@/components/molecules/ContentBox";
import { MainBanner } from "@/components/molecules/MainBanner";
import { ContentSection } from "@/components/organisms/ContentSection";
import { MainTemplate } from "@/components/templates/MainTemplate";

import ImgBottom from "@/styles/assets/img/img_main.png";
import Test01 from "@/styles/assets/img/test01.png";
import Test02 from "@/styles/assets/img/test02.png";
import Test03 from "@/styles/assets/img/test03.png";
import Test04 from "@/styles/assets/img/test04.png";
import Test05 from "@/styles/assets/img/test05.png";

export const MainPage = () => {
  return (
    <MainTemplate>
      <>
        <MainBanner />
        <ContentSection>
          <>
            <ContentBox title="추천 진단테스트">
              <TestItem image={Test01} />
              <TestItem image={Test02} />
              <TestItem image={Test03} />
              <TestItem image={Test04} />
              <TestItem image={Test05} />
            </ContentBox>

            <ContentBox title="인기글">
              <ContentPostItem />
              <ContentPostItem />
              <ContentPostItem />
              <ContentPostItem />
              <ContentPostItem />
            </ContentBox>

            <ContentBox>
              <ImgBanner height={130} img={ImgBottom} />
            </ContentBox>
          </>
        </ContentSection>
      </>
    </MainTemplate>
  );
};
