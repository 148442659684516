import styled from "styled-components";

export const IntroCarousel = styled.div`
  overflow: hidden;
  width: 100vw;
  height: calc(36vh + 40px);



  .slick-slider {
    padding-top: 40px;

    .slick-track {
      display: flex;
      align-items: center;
    }

    .slick-dots {
      display: flex !important;
      top: 0px;
      bottom: unset;
      align-items: center;
      justify-content: center;

      li {
        width: 10px;
        height: 10px;
        margin: 0 8px;
        transition: all 0.2s ease;

        button {
          padding: 4px;

          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background: ${(props) => props.theme.colors.white38};
            border-radius: 50%;
            opacity: 1;
            transition: all 0.2s ease;
          }
        }

        &.slick-active {
          width: 12px;
          height: 12px;
          transition: all 0.2s ease;

          button {
            &::before {
              width: 12px;
              height: 12px;
              background: ${(props) => props.theme.colors.white100};
              transition: all 0.2s ease;
            }
          }
        }
      }
    }
  }
`;
