import * as S from "./SubjectItem.style";
import { ReactComponent as IconCheck } from "@/styles/assets/svg/icon_check_circle.svg";

interface SubjectItemProps {
  data?: any;
  onSelect: (topicId: string) => void;
  isSelected: boolean;
}

export const SubjectItem = ({
  data,
  onSelect,
  isSelected,
}: SubjectItemProps) => {
  return (
    <S.SubjectItem>
      <S.SelectLabel>
        <IconCheck width={34} height={34} viewBox="2 2 20 20" />
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onSelect(data.id)}
        />
        <img
          src={`${process.env.REACT_APP_API_URL}/${data?.path}`}
          alt="관심주제 이미지"
        />
        <S.SelectTxt>{data.name}</S.SelectTxt>
      </S.SelectLabel>
    </S.SubjectItem>
  );
};
