import { UserInfoType } from "@/types/user_info.types";
import { atom, selector } from "recoil";
import { v1 } from "uuid";

export const userInfoState = atom<UserInfoType | null>({
  key: `userInfoState/${v1()}`,
  default: null,
});

export const userInfoSelector = selector({
  key: `userInfoSelector/${v1()}`,
  get: ({ get }) => {
    return get(userInfoState);
  },
  set: ({ set }, userInfo) => {
    set(userInfoState, userInfo);
  },
});
