import styled from "styled-components";

export const Form = styled.div`
  overflow-y: auto;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  flex-direction: column;
  gap: 30px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.scrollbar};
    border-radius: 2px;
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    overflow: hidden;
    height: auto;
    flex-shrink: 0;
  }
`;

export const FormTitBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FormTit = styled.h4`
  font-size: 1.8rem;
`;

export const FormTxt = styled.p`
  color: ${(props) => props.theme.colors.black60};
  font-size: 1.4rem;
`;

export const FormRowBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
