import { useNavigate } from "react-router-dom";

import { Button } from "@/components/atoms/Buttons";
import { InputRadio } from "@/components/atoms/Inputs/InputRadio";
import { InputText } from "@/components/atoms/Inputs/InputText/indext";
import { InputPassword } from "@/components/atoms/Inputs/InputPassword/indext";
import { InputAddress } from "@/components/atoms/Inputs/InputAddress";
import { Form } from "@/components/molecules/Form";
import { InputButtonSet } from "@/components/molecules/InputButtonSet";
import { JoinForm } from "@/components/organisms/JoinForm";
import { JoinTemplate } from "@/components/templates/JoinTemplate";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { ResponseType } from "@/types/response.types";
import { toast } from "react-toastify";
import { request } from "@/lib/api";
import { useState } from "react";
import PostCodeModal from "@/components/molecules/Modal/PostCodeModal";
import { ApiError } from "@/types/apiError";

export const JoinFormPage = () => {
  /** ============================= state 영역 ============================= */
  const [duplicateCheck, setDuplicateCheck] = useState<boolean>(false); // 아이디 중복 체크 상태값
  const [addressPopup, setAddressPopup] = useState<boolean>(false); // 주소 검색 팝업 상태값

  const navigate = useNavigate();
  /** 회원가입 메인 useForm  */
  const registerForm = useForm<any>({});
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = registerForm;

  /** ============================= API 영역 ============================= */

  /** 회원가입 api */
  const { mutateAsync: onSignup } = useMutation(
    ["ONSIGNUP"],
    (signUpData: any) =>
      request<ResponseType<any>>({
        method: "POST",
        url: `/auth/sign-up`, // 슬래시(/)를 앞에 추가하여 절대 경로로 변경
        data: signUpData,
      }),
    {
      onSuccess(data: ResponseType<any>) {
        if (data.success) {
          toast.success(data.message);
          navigate("/auth/login");
        } else {
          toast.error(data.message);
        }
      },
      onError(error: any) {
        console.log("error", error.message);
        toast.error(error.message);
      },
    }
  );

  /** 관리자 아이디 중복 체크 api */
  const { mutateAsync: isAdminIdUnique } = useMutation(
    ["IS_USER_ID_UNIQUE"],
    (userId: string) =>
      request<ResponseType<any>>({
        method: "GET",
        url: `/auth/duplicate-check/${userId}`,
      }),
    {
      onSuccess(data: ResponseType<any>) {
        if (data.success) {
          // 중복체크 완료 상태로 변경
          setDuplicateCheck(true);
          toast.success(data.message);
        } else {
          toast.error(data.message);
          setDuplicateCheck(false);
        }
      },
    }
  );

  /** ============================= 비즈니스 로직 영역 ============================= */

  /** 회원가입 함수  */
  const onSubmit = async (data: any) => {
    console.log("data", data);
    try {
      if (!duplicateCheck) {
        toast.error("아이디 중복 체크 해주세요.");
        return;
      }

      await onSignup({ ...data, provider: "local" }); // 회원가입 api 호출
    } catch (error: any) {
      console.log("error", error);
    }
  };

  // 주소 검색 팝업 열기
  const handlePopup = () => {
    setAddressPopup(!addressPopup);
  };

  // 주소 검색 팝업 완료
  const complete = (data: any) => {
    console.log("data", data);
    let addr = "";
    let extraAddr = "";
    if (data.userSelectedType === "R") {
      addr = data.address;
    } else {
      addr = data.jibunAddress;
    }
    if (data.userSelectedType === "R") {
      if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "" && data.apartment === "Y") {
        extraAddr +=
          extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
      }
      if (extraAddr !== "") {
        extraAddr = " (" + extraAddr + ")";
      }
      addr += extraAddr;
    }
    setValue("zip_code", data.zonecode);
    setValue("address", addr);

    setAddressPopup(false);
  };

  const checkId = () => {
    isAdminIdUnique(watch("user_id"));
  };
  /** ============================= 컴포넌트 영역 ============================= */

  /** ============================= useEffect 영역 ============================= */
  return (
    <JoinTemplate>
      <JoinForm
        title="회원가입"
        gridColumn="repeat(2, 1fr)"
        onSubmit={handleSubmit(onSubmit)}
        formButton={
          <Button
            type="submit"
            width="100vw"
            size="full"
            color="primary100"
            layout="solid"
            label="회원가입"
            // onClick={() => navigate("/auth/join/select")}
          />
        }
      >
        {/* 이용약관 동의 */}
        <Form title="서비스 이용약관 동의"></Form>

        {/* 회원가입 폼 */}
        <Form title="기본정보 입력">
          <InputButtonSet gridColumn="auto 110px">
            <InputText
              label="아이디"
              placeholder="아이디를 입력해 주세요."
              register={register("user_id")}
              required={true}
            />
            <Button
              type="button"
              width="110px"
              layout="outline"
              color="secondary100"
              size="input"
              label="아이디 확인"
              onClick={checkId}
            />
          </InputButtonSet>
          <InputPassword
            label="비밀번호"
            placeholder="비밀번호를 입력해 주세요."
            register={register("password")}
            required={true}
          />
          <InputPassword
            label="비밀번호 확인"
            placeholder="비밀번호를 재입력해 주세요."
            register={register("chk_password")}
            required={true}
          />
          <InputText
            label="이메일"
            placeholder="이메일을 입력해 주세요."
            register={register("email")}
            required={true}
          />
          <InputText
            label="이름"
            placeholder="이름을 입력해 주세요."
            register={register("username")}
            required={true}
          />
          <InputRadio
            label="성별"
            name="gender"
            data={[
              { value: "male", displayExpr: "남" },
              { value: "female", displayExpr: "여" },
            ]}
            onChange={registerForm?.setValue}
            inputForm={registerForm && registerForm}
            required={true}
          />
          <InputText
            label="생년월일"
            placeholder="생년월일 8자리를 입력해 주세요 (예 : 19900101)"
            register={register("birthdate")}
            required={true}
            maxLength={8}
          />
          <InputAddress
            label="주소"
            placeholder="상세주소를 입력해 주세요."
            openPost={handlePopup}
            register={register}
            required={true}
          />
        </Form>
      </JoinForm>
      {/* 우편 번호 검색 팝업 모달 */}
      {addressPopup && (
        <PostCodeModal
          title={"주소 검색"}
          onClose={handlePopup}
          complete={complete}
        />
      )}
    </JoinTemplate>
  );
};
