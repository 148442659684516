import { useNavigate } from "react-router-dom";

import * as S from "./ProfileCardItem.style";

import { ReactComponent as IconUser } from "@/styles/assets/svg/icon_user.svg";
import { ReactComponent as IconPlus } from "@/styles/assets/svg/icon_plus.svg";
import { profilAgeCalculater } from "@/lib/profilAgeCalculater";
import { navigatorPushScreen } from "@/lib/flutterFuntions";

interface ProfileCardItemProps {
  data?: any;
  onClick?: any;
  selectedProfile?: any;
}

export const ProfileCardItem = ({
  data,
  onClick,
  selectedProfile,
}: ProfileCardItemProps) => {
  const navigate = useNavigate();

  return (
    <S.ProfileCardItem
      onClick={() => (data ? onClick(data.id) : navigate("/auth/profile/form"))}
      $data={data ? true : false}
      $pinned={data ? selectedProfile === data.id && true : false}
    >
      <S.ProfileImg $data={data ? true : false}>
        {data?.profile_image && (
          <img
            src={`${process.env.REACT_APP_API_URL}/${data?.profile_image.path}`}
            alt="프로필 이미지"
          />
        )}
        {!data && (
          <>
            <IconUser width={180} height={180} />
            <S.ProfileAddIcon>
              <IconPlus width={50} height={50} />
            </S.ProfileAddIcon>
          </>
        )}
      </S.ProfileImg>

      {/* 아이콘 아래 프로필 정보 부분  */}
      <S.ProfileInfo
        $pinned={data ? selectedProfile === data.id && true : false}
      >
        {!data && <S.ProfileNoData>프로필 생성</S.ProfileNoData>}
        {data && (
          <>
            {data.name}
            <S.ProfileInfoDetail
              $pinned={data ? selectedProfile === data.id && true : false}
            >
              <span>{profilAgeCalculater(data.birth_date)}</span>
              <span>
                {data.gender === "female" ? "여자" : data.gender && "남자"}
              </span>
            </S.ProfileInfoDetail>
          </>
        )}
      </S.ProfileInfo>
    </S.ProfileCardItem>
  );
};
