import styled from "styled-components";

interface ButtonSetStyleProps {
  $direction: "column" | "row";
}

export const ButtonSet = styled.div<ButtonSetStyleProps>`
  display: flex;
  flex-direction: ${(props) => props.$direction};
  align-items: center;
  justify-content: ${(props) =>
    props.$direction === "row" ? "flex-start" : "center"};
  gap: ${(props) => (props.$direction === "row" ? "10px" : "16px")};
`;

export const ButtonSetTit = styled.span`
  color: ${(props) => props.theme.colors.white100};
  font-size: 1.8rem;
  font-weight: 600;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    font-size: 1.6rem;
  }
`;

export const ButtonSetContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
