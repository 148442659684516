import styled from "styled-components";

export const Header = styled.header`
  position: relative;
  display: flex;
  width: 100vw;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    padding: 0 6px;
  }
`;

export const HeaderTitBox = styled.div`
  position: absolute;
  display: flex;
  width: 100vw;
  height: 80px;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  z-index: 0;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: auto;
    height: 56px;
    left: 16px;
    top: 0px;

    & > svg {
      height: 26px;
    }
  }
`;

export const HeaderLeftBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const HeaderRightBox = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  z-index: 1;
`;

export const AlarmButton = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  border-radius: 10px;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: ${(props) => props.theme.colors.gray100};
    }
  }

  &:active {
    background: ${(props) => props.theme.colors.black10};
  }
`;
