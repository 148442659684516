import * as S from "./ContentBox.style";

interface ContentBoxProps {
  title?: string;
  children?: React.ReactNode;
}

export const ContentBox = ({ title, children }: ContentBoxProps) => {
  return (
    <S.ContentBox>
      {title && (
        <S.ContentTitBox>
          <S.ContentTit>{title}</S.ContentTit>
        </S.ContentTitBox>
      )}

      <S.Content>
        <S.ContentList>{children}</S.ContentList>
      </S.Content>
    </S.ContentBox>
  );
};
