import { Button } from "@/components/atoms/Buttons";
import * as S from "./MainBanner.style";
import ImgProfile from "@/styles/assets/img/img_profile.png";
import { ReactComponent as LogoBanner } from "@/styles/assets/svg/logo_banner_color.svg";
import { ReactComponent as LogoBannerWhite } from "@/styles/assets/svg/logo_banner_white.svg";
import { ReactComponent as ImgBanner } from "@/styles/assets/svg/img_banner.svg";

export const MainBanner = () => {
  return (
    <S.MainBanner>
      <S.BannerContent>
        <S.BannerTitBox>
          <div className="tablet-size">
            <LogoBanner width={200} height={30} />

            <div>
              재밌게 착착! 발달은 쑥쑥!
              <span>그로우러닝 AI 학습</span>
            </div>
          </div>

          <div className="mobile-size">
            <LogoBannerWhite width={140} height={20} />

            <div>
              학습수준 및<br />
              종합 솔루션 확인
            </div>
          </div>
        </S.BannerTitBox>

        <S.ImgButton>
          <div className="tablet-size">
            <Button
              type="button"
              size="sm"
              layout="solid"
              color="deepRed100"
              label="학습하러 가기"
            />
          </div>
          <div className="mobile-size">
            <Button
              type="button"
              size="sm"
              layout="outline"
              color="white100"
              label="맞춤 분석 보기"
            />
          </div>
        </S.ImgButton>
      </S.BannerContent>

      <S.ImgBanner>
        <ImgBanner />
        <S.ImgProfile>
          <img src={ImgProfile} alt="사용자 프로필 이미지" />
        </S.ImgProfile>
      </S.ImgBanner>
    </S.MainBanner>
  );
};
