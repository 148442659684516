import styled from "styled-components";

export const CarouselItem = styled.div`
  overflow: hidden;
  height: 36vh;
  margin: 0 5px;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

`;
