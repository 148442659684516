import { menus } from "@/data/menus";
import { NavBar } from "@/components/organisms/NavBar";
import * as S from "./MainTemplate.style";
import { Header } from "@/components/organisms/Header";
import { HeaderPopover } from "@/components/molecules/Popover/HeaderPopover";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { request } from "@/lib/api";
import { ResponseType } from "@/types/response.types";

interface MainTemplateProps {
  children?: React.ReactNode;
}

export const MainTemplate = ({ children }: MainTemplateProps) => {
  const [popover, setPopover] = useState(false);

  const selectProfileId = localStorage.getItem("selectProfileId");

  const { data: selectedProfile } = useQuery(
    ["USER_CHILD_PROFILE_SELECTED"],
    async () => {
      const result = await request<ResponseType<any>>({
        method: "GET",
        url: `profile/selected/${selectProfileId}`,
      });

      return result.data;
    },
    {
      onError: (error) => {
        console.error("Query Error:", error); // 에러 처리 로직
        // 추가적인 에러 처리 로직
      },
    }
  );

  console.log("selectedProfile", selectedProfile);

  return (
    <>
      <S.MainTemplate>
        <Header setPopover={setPopover} selectedProfile={selectedProfile} />
        <S.MainContentBox>{children}</S.MainContentBox>
        <NavBar menu={menus} />
      </S.MainTemplate>
      {popover === true && <HeaderPopover setPopover={setPopover} />}
    </>
  );
};
