import { UseFormRegister } from "react-hook-form";
import * as S from "./Inputs.style";

export interface InputsProps {
  type?: "text" | "password";
  label?: string;
  children?: React.ReactNode;
  placeholder?: string;
  direction?: "row" | "column";
  register?: any;
  value?: any;
  required?: boolean;
}

export const Inputs = ({
  label,
  children,
  direction = "column",
  required = false,
}: InputsProps) => {
  return (
    <S.InputBox $direction={direction}>
      {label && (
        <S.InputLabel $direction={direction}>
          {label} {required === true && <span>*</span>}
        </S.InputLabel>
      )}
      {children}
    </S.InputBox>
  );
};
