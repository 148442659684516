import { atom, selector } from "recoil";
import { v1 } from "uuid";

export const deviceTypeState = atom<string | null>({
  key: `deviceTypeState/${v1()}`,
  default: null,
});

export const deviceTypeStateSelector = selector({
  key: `deviceTypeSelector/${v1()}`,
  get: ({ get }) => {
    return get(deviceTypeState);
  },
  set: ({ set }, deviceType) => {
    set(deviceTypeState, deviceType);
  },
});
