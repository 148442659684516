import { Inputs, InputsProps } from "..";

interface InputTextProps extends InputsProps {
  label?: string;
  required?: boolean;
  maxLength?: number;
}

export const InputText = ({
  label,
  placeholder,
  direction,
  register,
  value,
  required,
  maxLength,
}: InputTextProps) => {
  return (
    <Inputs label={label && label} direction={direction} required={required}>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        {...register}
      />
    </Inputs>
  );
};
