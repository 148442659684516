import styled, { css } from "styled-components";

interface JoinFormStyleProps {
  $formButton?: boolean;
  $gridColumn?: string;
}

export const JoinForm = styled.form<JoinFormStyleProps>`
  overflow: hidden;
  display: grid;
  width: 100vw;
  height: calc(100vh - 80px);
  min-height: 100%;
  align-items: center;
  justify-content: center;
  grid-template-rows: ${(props) =>
    props.$formButton === true ? "auto 80px" : "1fr"};

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    grid-template-rows: ${(props) =>
      props.$formButton === true ? "auto 60px" : "1fr"};
    gap: 16px;
  }
`;

export const FormBox = styled.div<JoinFormStyleProps>`
  overflow-y: hidden;
  display: flex;
  height: ${(props) =>
    props.$formButton === true ? "calc(100vh - 160px)" : "calc(100vh - 80px)"};
  padding: 32px 16px;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    height: ${(props) =>
      props.$formButton === true
        ? "calc(100vh - 132px)"
        : "calc(100vh - 56px)"};
    padding: 16px;
    gap: 40px;
  }
`;

export const FormTitBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    gap: 10px;
  }
`;

export const FormTit = styled.h3`
  font-size: 2.8rem;
  font-weight: 800;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    font-size: 2.2rem;
  }
`;

export const FormTxt = styled.p`
  color: ${(props) => props.theme.colors.black38};
  font-size: 1.6rem;
  text-align: center;
  line-height: 160%;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    font-size: 1.4rem;
  }
`;

export const FormContent = styled.div<JoinFormStyleProps>`
  overflow: hidden;
  display: ${(props) => (props.$gridColumn ? "grid" : "flex")};
  width: 75vw;
  height: auto;
  align-items: center;
  justify-content: center;
  gap: 20px;

  ${(props) =>
    props.$gridColumn &&
    css`
      grid-template-columns: ${props.$gridColumn};
    `}

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    overflow-y: auto;
    display: flex;
    width: calc(100vw - 32px);
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 40px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.scrollbar};
      border: 1px solid ${(props) => props.theme.colors.white38};
      border-radius: 2px;
    }
  }
`;
