import styled from "styled-components";

interface ImgBannerStyleProps {
  $height?: number;
}

export const ImgBanner = styled.div<ImgBannerStyleProps>`
  overflow: hidden;
  width: 100%;
  height: ${(props) => props.$height}px;
  border-radius: 10px;

  img {
    width: 100%;
    height: ${(props) => props.$height}px;
    object-fit: cover;
    object-position: center;
  }
`;
