import { Inputs, InputsProps } from "..";
import * as S from "./InputProfileIcon.style";
import { ReactComponent as IconCheck } from "@/styles/assets/svg/icon_check_circle.svg";

interface InputProfileIconProps extends InputsProps {
  name: string;
  data: any;
  onChange?: (key: any, data: any) => void;
}

export const InputProfileIcon = ({
  label,
  name,
  data,
  onChange,
}: InputProfileIconProps) => {
  return (
    <Inputs label={label && label}>
      <S.InputProfileIcon>
        {data.map((item: any, idx: number) => {
          return (
            <S.ProfileIconLabel key={idx}>
              <S.CheckIcon>
                <IconCheck width={20} height={20} />
              </S.CheckIcon>
              <input
                type="radio"
                name={name}
                onChange={() => onChange?.(name, item.id)}
              />
              {item.icon}
            </S.ProfileIconLabel>
          );
        })}
      </S.InputProfileIcon>
    </Inputs>
  );
};
