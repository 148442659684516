import { useNavigate } from "react-router-dom";

import { Button } from "@/components/atoms/Buttons";
import { IntroCarousel } from "@/components/molecules/IntroCarousel";
import { IntroTemplate } from "@/components/templates/IntroTemplate";

export const IntroPage = () => {
  const navigate = useNavigate();
  return (
    <IntroTemplate>
      <>
        <IntroCarousel />
        <Button
          type="button"
          size="md"
          color="red100"
          layout="solid"
          label="시작하기"
          onClick={() => navigate("/auth/login")}
        />
      </>
    </IntroTemplate>
  );
};
