import { useRef } from 'react';
import * as S from "./Popover.style";

export interface PopoverProps {
  setPopover?: any;
  children?: React.ReactNode;
}

export const Popover = ({ setPopover, children }: PopoverProps) => {
  const popoverRef= useRef<HTMLDivElement>(null);

  const popoverOutsideClick = (e:any) => {
    if (popoverRef.current === e.target) {
      return setPopover(false);
    }
  }

  return <S.PopoverBg ref={popoverRef} onClick={(e:any) => popoverOutsideClick(e)}>{children}</S.PopoverBg>;
};
