import styled from "styled-components";

export const InputRadio = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

export const RadioLabel = styled.label`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 0 12px;
  color: ${(props) => props.theme.colors.black38};
  font-size: 1.6rem;
  font-weight: 700;
  white-space: nowrap;
  border: 1px solid ${(props) => props.theme.colors.black10};
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  input {
    display: none;
  }

  &:has(input:checked) {
    color: ${(props) => props.theme.colors.white100};
    background: ${(props) => props.theme.colors.secondary100};
    border: none;
  }
`;
