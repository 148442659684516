import { ChildrenProfile } from "@/types/children_profile.types";
import * as S from "./ButtonUserProfile.style";
import ImgProfile from "@/styles/assets/img/img_profile.png";

interface ButtonUserProfileProps {
  onClick?: () => void;
  selectedProfile?: ChildrenProfile;
}

export const ButtonUserProfile = ({
  onClick,
  selectedProfile,
}: ButtonUserProfileProps) => {
  return (
    <S.ButtonUserProfile type="button" onClick={onClick}>
      <S.UserProfile>
        <img
          src={
            selectedProfile?.profile_image !== null
              ? `${process.env.REACT_APP_API_URL}/${selectedProfile?.profile_image?.path}`
              : ImgProfile
          }
          alt="사용자 프로필 이미지"
        />
      </S.UserProfile>
    </S.ButtonUserProfile>
  );
};
