import { ReactComponent as IconProfile01 } from "@/styles/assets/svg/user_profile01.svg";
import { ReactComponent as IconProfile02 } from "@/styles/assets/svg/user_profile02.svg";
import { ReactComponent as IconProfile03 } from "@/styles/assets/svg/user_profile03.svg";
import { ReactComponent as IconProfile04 } from "@/styles/assets/svg/user_profile04.svg";
import { ReactComponent as IconProfile05 } from "@/styles/assets/svg/user_profile05.svg";
import { ReactComponent as IconProfile06 } from "@/styles/assets/svg/user_profile06.svg";
import { ReactComponent as IconProfile07 } from "@/styles/assets/svg/user_profile07.svg";
import { ReactComponent as IconProfile08 } from "@/styles/assets/svg/user_profile08.svg";

export const userIcon = [
  {
    id: 1,
    icon: <IconProfile01 width={50} height={60} />,
  },
  {
    id: 2,
    icon: <IconProfile02 width={50} height={60} />,
  },
  {
    id: 3,
    icon: <IconProfile03 width={50} height={60} />,
  },
  {
    id: 4,
    icon: <IconProfile04 width={50} height={60} />,
  },
  {
    id: 5,
    icon: <IconProfile05 width={50} height={60} />,
  },
  {
    id: 6,
    icon: <IconProfile06 width={50} height={60} />,
  },
  {
    id: 7,
    icon: <IconProfile07 width={50} height={60} />,
  },
  {
    id: 8,
    icon: <IconProfile08 width={50} height={60} />,
  },
];
