import { useNavigate } from "react-router-dom";

import { Button } from "@/components/atoms/Buttons";
import { SubjectList } from "@/components/molecules/SubjectList";
import { JoinForm } from "@/components/organisms/JoinForm";
import { JoinTemplate } from "@/components/templates/JoinTemplate";

import { request } from "@/lib/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ResponseType } from "@/types/response.types";
import { useState } from "react";
import { toast } from "react-toastify";

/**
 * 처음 로그인 했을때 is_first_login 값이 true 일때만 보여지는 페이지
 */
export const JoinSelectPage = () => {
  const navigate = useNavigate();
  /** ============================= state 영역 ============================= */
  const [selectedTopic, setSelectedTopic] = useState<string[]>([]);

  /** ============================= API 영역 ============================= */

  /** 토픽 리스트 조회  */
  const { data: topic } = useQuery(
    ["GET_TOPIC_LIST"],
    async () => {
      const result = await request<ResponseType<any>>({
        method: "GET",
        url: `profile/topics`,
      });

      return result.data;
    },
    {
      onError: (error) => {
        console.error("Query Error:", error); // 에러 처리 로직
        // 추가적인 에러 처리 로직
      },
    }
  );

  // 사진추가 버튼 클릭시 실행 함수
  const { mutateAsync: saveTopic } = useMutation({
    mutationFn: (data: string[]) => {
      return request({
        method: "POST",
        url: "/profile/topics",
        data: { topicIds: data },
      });
    },
    onSuccess: () => {
      toast.success("관심 주제 설정이 완료되었습니다.");
      navigate("/auth/join/buy");
    },
    onError: () => {
      toast.error("관심 주제 설정에 실패하였습니다.");
    },
  });

  /** ============================= 비즈니스 로직 영역 ============================= */

  const onSaveTopic = async () => {
    if (selectedTopic.length === 0) {
      alert("관심 주제를 선택해 주세요.");
      return;
    }
    try {
      await saveTopic(selectedTopic);
    } catch (error) {
      console.error("Error saving topics:", error);
    }
  };

  const handleTopicSelect = (topicId: string) => {
    setSelectedTopic((prev) => {
      if (prev.includes(topicId)) {
        return prev.filter((id) => id !== topicId);
      }
      return [...prev, topicId];
    });
  };

  console.log(selectedTopic);
  /** ============================= 컴포넌트 영역 ============================= */

  /** ============================= useEffect 영역 ============================= */

  return (
    <JoinTemplate>
      <JoinForm
        title="관심 주제 설정"
        text="관련 정보와 알림을 가장 빠르게 받아보세요."
        formButton={
          <Button
            type="button"
            width="100vw"
            size="full"
            color="primary100"
            layout="solid"
            label="설정 저장"
            onClick={onSaveTopic}
          />
        }
      >
        <SubjectList
          data={topic}
          onTopicSelect={handleTopicSelect}
          selectedTopic={selectedTopic}
        />
      </JoinForm>
    </JoinTemplate>
  );
};
