import { userInfoSelector } from "@/recoil/userInfo";
import { Navigate, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { navigatorRoutePushScreen } from "@/lib/flutterFuntions";
import { deviceTypeState } from "@/recoil/deviceType";
import { LoadingSpinner } from "@/components/atoms/LoadingSpinner";

interface PrivateRouteProps {
  component: React.ReactNode;
  authenticated?: string | null;
}

const PrivateRoute = ({ component }: PrivateRouteProps) => {
  const userInfo = useRecoilValue(userInfoSelector);
  const deviceType = useRecoilValue(deviceTypeState);
  const [isLoading, setIsLoading] = useState(true);

  const selectProfileId = localStorage.getItem("selectProfileId");
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // 로그인되지 않은 경우 인트로 페이지로 이동
  if (!userInfo) {
    return <Navigate to="/intro" />;
  }

  console.log(deviceType, "deviceType");

  // 패드이고 프로필이 선택된 경우 플러터 페이지로 이동
  if (
    deviceType === "pad" &&
    selectProfileId !== null &&
    userInfo !== undefined
  ) {
    // navigatorRoutePushScreen("/", "", () => {});
    navigate("/home");
    return null; // 플러터 페이지로 이동 후 아무것도 렌더링하지 않음
  } else {
    // 휴대폰 일 때
    return <Navigate to="/home" replace />;
  }
};

export default PrivateRoute;
