import styled from "styled-components";

export const ButtonGrowLearn = styled.button`
  position: relative;
  display: flex;
  width: 100%;
  padding: 26px;
  background: ${(props) => props.theme.gradients.growLearn};
  border-radius: 10px;
  border: none;
  box-shadow: ${(props) => props.theme.shadows.item};
  flex-direction: column;
  gap: 16px;
`;

export const ButtonTit = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.white100};
  font-size: 2.2rem;
  font-weight: 800;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;

  p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
`;

export const ButtonImg = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
`;
