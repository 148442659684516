import styled from "styled-components";

export const ContentSection = styled.section`
  display: flex;
  width: calc(50vw - 24px);
  justify-content: space-between;
  flex-direction: column;

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: 100%;
    gap: 30px;
  }
`;
