import styled from "styled-components";

export const MyInfoBox = styled.div`
  display: grid;
  width: calc(100vw - 32px);
  padding: 20px;
  background: ${(props) => props.theme.colors.profileBg};
  border-radius: 10px;
  grid-template-columns: calc(65% - 10px) calc(35% - 10px);
  align-items: center;
  gap: 20px;
`;

export const MyInfo = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 20px;
`;

export const MyProfileImg = styled.label`
  position: relative;
  width: 72px;
  height: 72px;
  padding: 6px;
  background: ${(props) => props.theme.colors.white100};
  box-shadow: ${(props) => props.theme.shadows.item};
  border-radius: 50%;

  input {
    display: none;
  }

  & > svg {
    path {
      fill: ${(props) => props.theme.colors.black30};
    }
  }

  span {
    position: absolute;
    right: -2px;
    bottom: -6px;
    background: ${(props) => props.theme.colors.white100};
    border-radius: 50%;

    svg {
      path {
        fill: ${(props) => props.theme.colors.secondary100};
      }
    }
  }
`;

export const MyUser = styled.div`
  display: flex;
  font-size: 2.2rem;
  font-weight: 600;
  align-items: flex-start;
  flex-direction: column;
  gap: 7px;
`;

export const UserTypeChip = styled.span`
  padding: 4px 8px;
  color: ${(props) => props.theme.colors.white100};
  font-size: 1.2rem;
  font-weight: 700;
  background: ${(props) => props.theme.colors.primary100};
  border-radius: 4px;
`;

export const UserBenefitBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const Benefit = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.secondary100};
  font-size: 1.6rem;
  font-weight: 800;
  flex-direction: column;
  gap: 3px;

  span {
    color: ${(props) => props.theme.colors.black60};
    font-size: 1.2rem;
    font-weight: 400;
  }
`;
