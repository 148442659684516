import styled from "styled-components";

export const InputAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputPostalCode = styled.div`
  display: grid;
  grid-template-columns: auto 130px;
  gap: 12px;
`;
