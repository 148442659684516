import { Inputs, InputsProps } from "..";
import * as S from "./InputRadio.style";

interface InputRadioProps extends InputsProps {
  name: string;
  data: any;
  onChange?: (key: any, data: any) => void;
  inputForm?: any;
}

export const InputRadio = ({
  label,
  name,
  data,
  register,
  inputForm,
  onChange,
  required,
}: InputRadioProps) => {
  return (
    <Inputs label={label && label} required={required}>
      <S.InputRadio>
        {data.map((item: any, idx: number) => {
          return (
            <S.RadioLabel key={idx}>
              <input
                type="radio"
                name={name}
                value={item.value}
                {...register}
                checked={inputForm?.watch(name) == item.value}
                onChange={() => onChange?.(name, item.value)}
              />
              {item.displayExpr}
            </S.RadioLabel>
          );
        })}
      </S.InputRadio>
    </Inputs>
  );
};
