import styled from "styled-components";

export const ButtonUserProfile = styled.button`
  padding: 4px;
  background: none;
  border: none;
  border-radius: 10px;

  &:active {
    background: ${(props) => props.theme.colors.black10};

    & > div {
      box-shadow: none;
    }
  }
`;

export const UserProfile = styled.div`
  overflow: hidden;
  display: flex;
  width: 50px;
  height: 50px;
  padding: 5px;
  background: ${(props) => props.theme.colors.white100};
  border-radius: 50%;
  box-shadow: ${(props) => props.theme.shadows.profile};
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
  }

  // 모바일 환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
    padding: 3px;
  }
`;
